import {
  SeanceTechnique,
  TechniqueContext,
} from "domain/entities/techniques.entities";
import { Dispatch, SetStateAction } from "react";
import { HiLightBulb, HiPlus } from "react-icons/hi";
import { translate } from "ui/i18n";
import { InlineTechniqueCard } from "ui/pages/app/seances/create-seance/steps/add-content/components/InlineTechniqueCard";
import { ReactSortable } from "react-sortablejs";
import { deleteSeanceTechnique } from "domain/seances/seances.actions";
import { useAppDispatch } from "ui/hook/store";

interface IProps {
  context: TechniqueContext;
  selectedTechniques: SeanceTechnique[];
  setSelectedTechniques: Dispatch<SetStateAction<SeanceTechnique[]>>;
  onAdd: (context: TechniqueContext) => void;
  isMoving: boolean;
  setIsMoving: Dispatch<SetStateAction<boolean>>;
  seanceId: number;
  fetchSeance: (id?: number) => Promise<void>;
}

export function TechniquesZone({
  context,
  selectedTechniques,
  setSelectedTechniques,
  onAdd,
  isMoving,
  setIsMoving,
  seanceId,
  fetchSeance,
}: IProps) {
  const dispatch = useAppDispatch();

  const removeSelectedTechnique = async (technique: SeanceTechnique) => {
    const result = await dispatch(
      deleteSeanceTechnique({
        techniqueId: technique.id,
        seanceId,
      })
    ).unwrap();

    if (result.success) {
      fetchSeance();
    }
  };

  const handleListChange = (techniques: SeanceTechnique[]) => {
    setSelectedTechniques(
      techniques.map((t, index) => {
        return { ...t, order: index };
      })
    );
  };

  return (
    <div className="flex w-full flex-col rounded-[30px] bg-darkGrey-200 p-4 md:p-6">
      <div className="mb-3 flex justify-between md:mb-4">
        <h3 className="text-lg font-bold text-secondaryGrey-900 md:text-xl">
          {translate(`ns1:Techniques.${context}`)}
        </h3>
        <div className="flex space-x-2">
          <button onClick={() => onAdd(context)}>
            <HiPlus size={24} color="#2B3674" />
          </button>
        </div>
      </div>

      <ReactSortable
        list={
          [
            ...selectedTechniques
              .sort((a, b) => a.order - b.order)
              .map((tech) => {
                return { ...tech, chosen: true };
              }),
          ] as (SeanceTechnique & {
            id: number;
            chosen: true;
          })[]
        }
        setList={handleListChange}
        className={`space-y-4 ${
          isMoving
            ? "mb-4 min-h-16 rounded-[20px] border border-dashed border-darkGrey-900 p-1"
            : ""
        }`}
        group="techniques-sortable"
        onStart={() => setIsMoving(true)}
        onEnd={() => setIsMoving(false)}
      >
        {selectedTechniques.map((technique, index) => (
          <div className="cursor-pointer" key={context + "technique" + index}>
            <InlineTechniqueCard
              technique={technique.technique}
              onDelete={() => removeSelectedTechnique(technique)}
              fetchSeance={fetchSeance}
            />
          </div>
        ))}
      </ReactSortable>
      {selectedTechniques.length === 0 && (
        <div className="flex items-center space-x-4 rounded-[20px] bg-darkGrey-100 p-4">
          <div className="h-10 w-10">
            <span className="flex h-10 w-10 items-center justify-center rounded-full bg-secondaryGrey-900">
              <HiLightBulb size={24} color="#fff" />
            </span>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs font-bold text-secondaryGrey-900 md:text-sm">
              {translate(`ns1:CreateSeance.ContextGoals.${context}`)}
            </p>
            <p className="text-xs font-medium text-secondaryGrey-900 md:text-sm">
              {translate("ns1:CreateSeance.ClickToAddContent")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
