import { Seance } from "domain/entities/seances.entities";
import { updateSeance } from "domain/seances/seances.actions";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { HiPencil } from "react-icons/hi";
import { useAppDispatch } from "ui/hook/store";
import { translate } from "ui/i18n";
import { INTextarea } from "ui/pages/app/components/INTextarea";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { Inputs } from "ui/pages/inputsType";
import { Snackbar } from "ui/pages/snackbar";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  seance: Seance;
  seanceId: number;
  fetchSeance: () => Promise<void>;
  isLoading: boolean;
  editObj: boolean;
  setEditObj: Dispatch<SetStateAction<boolean>>;
}

export function UpdatedObjective({
  seance,
  seanceId,
  fetchSeance,
  isLoading,
  editObj,
  setEditObj,
}: IProps) {
  const dispatch = useAppDispatch();
  const isDesktop = useWindowWidth();

  const objectiveFormManager = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      objectif: seance.objectif,
    },
  });

  const saveObjective = async (data: Inputs) => {
    const result = await dispatch(
      updateSeance({
        seance: {
          objectif: data.objectif,
        },
        id: seanceId,
      })
    ).unwrap();

    if (result.success && result.id) {
      fetchSeance();
      Snackbar.showSuccess({
        title: translate("ns1:CreateSeance.UpdatedObjective"),
      });
      setEditObj(false);
    }
  };

  return (
    <div className="w-full rounded-2xl bg-white">
      <div className="p-6">
        <div className="mb-2 flex w-full justify-between">
          <p className="text-sm font-bold text-secondaryGrey-900 md:text-base">
            {translate("ns1:CreateSeance.SeanceGoal")}
          </p>
          {!editObj && (
            <button onClick={() => setEditObj(true)}>
              <HiPencil color="#485585" size={24} />
            </button>
          )}
        </div>
        {!editObj ? (
          <p className="text-sm text-darkGrey-700 md:text-base">
            {seance.objectif}
          </p>
        ) : (
          <form onSubmit={objectiveFormManager.handleSubmit(saveObjective)}>
            <INTextarea
              type="textarea"
              error={objectiveFormManager.formState.errors.objectif}
              placeholder={translate("ns1:Input.Placeholders.WriteHere")}
              className="mb-4 md:mb-6"
              register={{
                ...objectiveFormManager.register("objectif", {
                  required: translate("ns1:Input.Errors.Required"),
                }),
              }}
              rows={isDesktop ? 2 : 4}
            />
            <div className="flex flex-col space-x-0 space-y-2 md:flex-row md:space-x-4 md:space-y-0">
              <PrimaryButton
                type="submit"
                height={isDesktop ? "46px" : "36px"}
                width="100%"
                disabled={!objectiveFormManager.formState.isValid || isLoading}
              >
                {translate("ns1:Button.Validate")}
              </PrimaryButton>

              <PrimaryButton
                height={isDesktop ? "46px" : "36px"}
                width="100%"
                disabled={isLoading}
                outlined
                onClick={() => setEditObj(false)}
              >
                {translate("ns1:Button.Cancel")}
              </PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
