import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AlertSnackbar } from "ui/pages/snackbar/AlertSnackbar";
import { SubscriptionSnackbar } from "ui/pages/snackbar/SubscriptionSnackbar";

export class Snackbar {
  private navigate = useNavigate();

  static async showSuccess({ title, text }: { title: string; text?: string }) {
    return toast.custom(
      (t) => <AlertSnackbar type="success" title={title} text={text} t={t} />,
      { duration: 4000 }
    );
  }

  static async showError({ title, text }: { title: string; text?: string }) {
    return toast.custom(
      (t) => <AlertSnackbar type="error" title={title} text={text} t={t} />,
      { duration: 4000 }
    );
  }

  static async showSubscriptionAlert(
    onClickNavigate: () => void,
    type: "seance" | "technique" | "create-seance"
  ) {
    return toast.custom(
      (t) => (
        <SubscriptionSnackbar
          t={t}
          onClickNavigate={onClickNavigate}
          type={type}
        />
      ),
      {
        duration: 6000,
        id: "subscription",
      }
    );
  }

  static async removeSubscriptionAlert() {
    return toast.remove("subscription");
  }
}
