import { HttpService } from "data/http-service";
import {
  Technique,
  TechniqueContext,
  TechniquesFilters,
} from "domain/entities/techniques.entities";
import {
  DurationFilter,
  ActivitiesPageFilters,
  ActivityAdvice,
} from "domain/entities/activities.entities";
import { TechniquesGateway } from "domain/techniques/techniques.gateway";
import {
  techniqueDtoSchema,
  techniquesDtoSchema,
} from "data/techniques/techniques.dto.schemas";
import { Snackbar } from "ui/pages/snackbar";
import { translate } from "ui/i18n";
import { AxiosError } from "axios";
import { getActivitiesFilters } from "data/activities/get-activities-filters";
import { adviceDtoSchema } from "data/activities/activities.dto.schemas";

export class TechniquesHttpGateway implements TechniquesGateway {
  async getTechniques(
    filters: TechniquesFilters &
      Partial<ActivitiesPageFilters> &
      Partial<{ duration: DurationFilter }>
  ): Promise<{
    techniques: Technique[];
    success: boolean;
  }> {
    const url = "techniques";
    return HttpService.get(url, { params: getActivitiesFilters(filters) })
      .then((response) => {
        if (response.status === 200) {
          const parsedTechniques = techniquesDtoSchema.safeParse(
            response.data.data
          );
          if (parsedTechniques.success) {
            return {
              techniques: parsedTechniques.data,
              success: true,
            };
          } else return { techniques: [], success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Techniques.FindError"),
          });
          return {
            techniques: [],
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Techniques.FindError"),
        });
        return { techniques: [], success: false };
      });
  }

  async getHomeTechniques(
    filters: TechniquesFilters &
      Partial<ActivitiesPageFilters> &
      Partial<{ duration: DurationFilter }>
  ): Promise<{
    techniques: Technique[];
    success: boolean;
    context: TechniqueContext;
  }> {
    const url = "techniques";
    return HttpService.get(url, { params: filters })
      .then((response) => {
        if (response.status === 200) {
          const parsedTechniques = techniquesDtoSchema.safeParse(
            response.data.data
          );
          if (parsedTechniques.success) {
            return {
              techniques: parsedTechniques.data,
              success: true,
              context: filters.context,
            };
          } else
            return { techniques: [], success: false, context: filters.context };
        } else {
          // Snackbar.showError({
          //   title: translate("ns1:Techniques.FindError"),
          // });
          return {
            techniques: [],
            success: false,
            context: filters.context,
          };
        }
      })
      .catch((error: AxiosError) => {
        // Snackbar.showError({
        //   title: translate("ns1:Techniques.FindError"),
        // });
        return { techniques: [], success: false, context: filters.context };
      });
  }

  async getTechnique(id: number): Promise<{
    technique: Technique | null;
    success: boolean;
  }> {
    const url = `techniques/${id}`;
    return HttpService.get(url)
      .then((response) => {
        if (response.status === 200) {
          const parsedTechnique = techniqueDtoSchema.safeParse(response.data);
          if (parsedTechnique.success) {
            return {
              technique: parsedTechnique.data,
              success: true,
            };
          } else return { technique: null, success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Technique.FindError"),
          });
          return {
            technique: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Technique.FindError"),
        });
        return { technique: null, success: false };
      });
  }

  async getTechniqueAdvice(id: number): Promise<{
    advice: ActivityAdvice | null;
    success: boolean;
  }> {
    const url = `conseils/random`;
    return HttpService.get(url, { params: { techniqueId: id } })
      .then((response) => {
        if (response.status === 200) {
          const parsedAdvice = adviceDtoSchema.safeParse(response.data);
          if (parsedAdvice.success) {
            return {
              advice: parsedAdvice.data,
              success: true,
            };
          } else return { advice: null, success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Advice.FindError"),
          });
          return {
            advice: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Advice.FindError"),
        });
        return { advice: null, success: false };
      });
  }

  async getFavoritesTechniques(techniqueIds: number[]): Promise<{
    techniques: Technique[];
    success: boolean;
  }> {
    const url = "techniques";
    const ids = techniqueIds.length > 0 ? techniqueIds : [-1];
    return HttpService.get(url, { params: { "id[$in]": ids } })
      .then((response) => {
        if (response.status === 200) {
          const parsedTechniques = techniquesDtoSchema.safeParse(
            response.data.data
          );
          if (parsedTechniques.success) {
            return {
              techniques: parsedTechniques.data,
              success: true,
            };
          } else return { techniques: [], success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Techniques.FindError"),
          });
          return {
            techniques: [],
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Techniques.FindError"),
        });
        return { techniques: [], success: false };
      });
  }
}
