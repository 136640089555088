export function BgLightGreen500() {
  return (
    <svg
      className="rounded-[10px]"
      viewBox="0 0 468 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_964_12291)">
        <rect width="468" height="178" fill="#53BBAD" />
        <g clipPath="url(#clip1_964_12291)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M583.742 -36.7704C587.312 -40.6851 593.375 -40.9598 597.283 -37.384C605.459 -29.9045 612.964 -18.6502 618.776 -7.13963C624.623 4.43765 629.219 17.1899 631.06 28.1667C631.973 33.6116 632.307 39.1898 631.426 44.1898C630.554 49.144 628.19 54.9341 622.473 58.3177C616.785 61.683 610.293 61.174 604.56 59.3137C598.766 57.4334 592.305 53.7475 585.143 48.4757C560.744 30.5173 538.045 28.8884 523.646 35.1622C509.864 41.1664 501.323 55.3461 505.868 75.8299C510.62 97.2481 512.661 113.75 511.892 126.166C511.124 138.564 507.389 148.84 498.549 154.811C490.177 160.466 479.712 160.461 470.074 159.045C460.18 157.591 448.712 154.232 436.765 150.643C419.956 145.592 408.148 149.305 401.528 155.094C394.79 160.985 392.317 169.806 395.084 178.121C396.758 183.151 394.044 188.588 389.022 190.264C384 191.941 378.572 189.223 376.898 184.193C371.605 168.291 376.552 151.445 388.921 140.63C401.407 129.712 420.394 125.679 442.273 132.252C454.507 135.928 464.599 138.834 472.857 140.048C481.372 141.299 485.652 140.364 487.829 138.893C489.538 137.739 492.156 134.694 492.758 124.977C493.359 115.279 491.786 100.874 487.154 79.9949C480.795 51.3382 493.069 27.5457 515.998 17.5559C538.309 7.83555 568.217 12.1908 596.495 33.0043C602.873 37.6982 607.44 40.0655 610.469 41.0486C611.332 41.3284 611.963 41.4651 612.405 41.5292C612.452 41.344 612.5 41.1207 612.547 40.8536C612.901 38.8473 612.88 35.6746 612.154 31.3462C610.717 22.7764 606.905 11.893 601.67 1.52602C596.401 -8.90764 590.149 -17.9063 584.354 -23.2079C580.446 -26.7836 580.171 -32.8558 583.742 -36.7704ZM612.12 42.3169C612.119 42.3154 612.129 42.2959 612.153 42.263C612.133 42.302 612.121 42.3184 612.12 42.3169ZM613.204 41.5736C613.236 41.5658 613.253 41.5639 613.253 41.5649C613.254 41.5659 613.238 41.5698 613.204 41.5736Z"
            fill="#9BECE1"
          />
          <path
            d="M405.458 52.9058C400.024 54.6621 394.198 51.6792 392.447 46.2432L387.179 29.8924C385.427 24.4564 388.413 18.6258 393.847 16.8695L410.148 11.6012C415.583 9.84482 421.408 12.8278 423.16 18.2638L428.428 34.6146C430.179 40.0506 427.194 45.8811 421.759 47.6375L405.458 52.9058Z"
            fill="#68D8C9"
          />
          <path
            d="M440.539 80.6765C435.595 77.8176 433.899 71.4889 436.751 66.541L439.339 62.0529C442.191 57.1049 448.511 55.4114 453.455 58.2703L457.923 60.8536C462.867 63.7125 464.563 70.0411 461.71 74.9891L459.123 79.4772C456.271 84.4251 449.951 86.1186 445.007 83.2597L440.539 80.6765Z"
            fill="#68D8C9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_964_12291">
          <rect width="468" height="178" fill="white" />
        </clipPath>
        <clipPath id="clip1_964_12291">
          <rect
            width="287.558"
            height="240"
            fill="white"
            transform="matrix(-1 0 0 1 655.559 -45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
