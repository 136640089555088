import { createAppAsyncThunk } from "domain/store.thunk";
import {
  AuthManagement,
  LoginUser,
  RegisterUser,
  UpdateUser,
} from "domain/entities/auth.entities";
import {
  getSessionFromLocalStorage,
  removeSessionInLocalStorage,
} from "data/auth/auth-session-storage";
import { HttpService } from "data/http-service";

export const registerUser = createAppAsyncThunk(
  "auth/registerUser",
  async (user: RegisterUser, { extra: { authGateway } }) => {
    return await authGateway.register(user);
  }
);

export const updateUser = createAppAsyncThunk(
  "auth/updateUser",
  async (user: UpdateUser, { extra: { authGateway } }) => {
    return await authGateway.updateUser(user);
  }
);

export const verifyUser = createAppAsyncThunk(
  "auth/verifyUser",
  async (body: AuthManagement, { extra: { authGateway } }) => {
    return await authGateway.verifyUser(body);
  }
);

export const verifyEmail = createAppAsyncThunk(
  "auth/verifyEmail",
  async (body: AuthManagement, { extra: { authGateway } }) => {
    return await authGateway.verifyEmail(body);
  }
);

export const loginUser = createAppAsyncThunk(
  "auth/login",
  async (user: LoginUser, { extra: { authGateway } }) => {
    return await authGateway.login(user);
  }
);

export const resendVerifyUser = createAppAsyncThunk(
  "auth/resendVerifyUser",
  async (body: AuthManagement, { extra: { authGateway } }) => {
    return await authGateway.resendVerifyUser(body);
  }
);

export const checkUserIsLogged = createAppAsyncThunk(
  "auth/checkUserIsLogged",
  (_, { extra: { authGateway } }) => {
    const session = getSessionFromLocalStorage();
    if (session?.accessToken) {
      HttpService.setAccessToken(session.accessToken);
      return { isLogged: true };
    }
    return { isLogged: false };
  }
);

export const getUserInfos = createAppAsyncThunk(
  "auth/getUserInfos",
  async (_, { extra: { authGateway } }) => {
    return await authGateway.getUserInfos();
  }
);

export const logoutUser = createAppAsyncThunk(
  "auth/logout",
  async (_, { extra: { authGateway } }) => {
    removeSessionInLocalStorage();
    window.location.href = "/auth";
  }
);

export const getPortalSession = createAppAsyncThunk(
  "auth/getPortalSession",
  async (_, { extra: { authGateway } }) => {
    return await authGateway.getPortalSession();
  }
);

export const sendResetPassword = createAppAsyncThunk(
  "auth/sendResetPassword",
  async (email: string, { extra: { authGateway } }) => {
    return await authGateway.sendResetPassword(email);
  }
);

export const sendNewPassword = createAppAsyncThunk(
  "auth/sendNewPassword",
  async (
    body: { token: string; password: string },
    { extra: { authGateway } }
  ) => {
    return await authGateway.sendNewPassword(body);
  }
);

export const updatePassword = createAppAsyncThunk(
  "auth/updatePassword",
  async (
    body: { email: string; password: string; oldPassword: string },
    { extra: { authGateway } }
  ) => {
    return await authGateway.updatePassword(body);
  }
);

export const updatePersonalInfos = createAppAsyncThunk(
  "auth/updatePersonalInfos",
  async (
    body: {
      data: {
        firstName?: string;
        lastName?: string;
        jobRole?: string;
        newsletter?: boolean | string;
        email?: string;
      };
      userId: number;
    },
    { extra: { authGateway } }
  ) => {
    return await authGateway.updatePersonalInfos(body);
  }
);
