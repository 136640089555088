import { createAppAsyncThunk } from "domain/store.thunk";

export const setTechniqueFavorite = createAppAsyncThunk(
  "activities/setTechniqueFavorite",
  async (techniqueId: number, { extra: { activitiesGateway } }) => {
    return await activitiesGateway.setTechniqueFavorite(techniqueId);
  }
);

export const setSeanceFavorite = createAppAsyncThunk(
  "activities/setSeanceFavorite",
  async (seanceId: number, { extra: { activitiesGateway } }) => {
    return await activitiesGateway.setSeanceFavorite(seanceId);
  }
);

export const getFavoriteActivities = createAppAsyncThunk(
  "activities/getFavoriteActivities",
  async (_, { extra: { activitiesGateway } }) => {
    return await activitiesGateway.getFavoriteActivities();
  }
);

export const removeFavoriteActivity = createAppAsyncThunk(
  "activities/removeFavoriteActivity",
  async (id: number, { extra: { activitiesGateway } }) => {
    return await activitiesGateway.removeFavoriteActivity(id);
  }
);
