import { Seance } from "domain/entities/seances.entities";

export const getDurationInHours = (duration: number) => {
  const days = Math.floor(duration / 1440);
  const remainingTime = duration - Math.floor(days * 1440);
  const hours = Math.floor(remainingTime / 60);
  const minutes = Math.floor(remainingTime - hours * 60);

  return duration === 0
    ? "0min"
    : `${days ? days + "j" : ""} ${
        hours ? (days ? `0${hours}`.slice(-2) : hours) + "h" : ""
      } ${minutes ? (hours ? `0${minutes}`.slice(-2) : minutes) + "min" : ""}`;
};

export const parseDuration = (duration: string) => {
  const [hours, minutes] = duration.split(":");
  return (hours ? parseInt(hours) * 60 : 0) + (minutes ? parseInt(minutes) : 0);
};

export const formatDuration = (duration: string) => {
  const hours = Math.floor(parseInt(duration) / 60);
  const minutes = parseInt(duration) % 60;
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const getParticipantsToString = (participants: number) => {
  return participants > 0 ? `lte${participants}` : "gte20";
};

export const getTotalSeanceDuration = (seance: Seance) => {
  if (seance.userId && seance?.techniques?.length > 0) {
    return seance.techniques.reduce(
      (acc, technique) => acc + Number(technique.duration),
      0
    );
  } else return Number(seance.duration) || 0;
};
