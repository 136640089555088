import {
  ActivitiesPageFilters,
  DurationFilter,
} from "domain/entities/activities.entities";

export const getFiltersCount = (
  filters: ActivitiesPageFilters & {
    [key: string]: string[] | string | boolean | number | DurationFilter;
  },
  minDuration?: number,
  maxDuration?: number
) => {
  let count = 0;
  if (filters.contexts?.length > 0) count++;
  if (filters.types?.length > 0) count++;
  if (filters.objectives?.length > 0) count++;
  if (filters.participants) count++;
  if (filters.modalities?.length > 0) count++;
  if (filters.selectedDuration?.length > 0) count++;
  if (
    filters.duration &&
    typeof minDuration !== "undefined" &&
    typeof maxDuration !== "undefined" &&
    (filters.duration.$gte !== minDuration ||
      filters.duration.$lte !== maxDuration)
  ) {
    count++;
  }
  return count;
};
