import { translate } from "ui/i18n";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { HiMail } from "react-icons/hi";
import { INInput } from "ui/pages/app/components/INInput";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Inputs } from "ui/pages/inputsType";
import { useAppDispatch } from "ui/hook/store";
import { verifyEmail } from "domain/auth/auth.actions";

export function Auth() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(
      verifyEmail({ action: "checkUnique", value: { email: data.email } })
    ).unwrap();

    if (result.emailExists === true) {
      navigate("/login", { state: { email: data.email } });
    } else {
      navigate("/register", { state: { email: data.email } });
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">{translate("ns1:Auth.WelcomeToInnizio")}</h2>
      <h4 className="mt-2 text-center md:mt-4">
        {translate("ns1:Auth.PrepareYourSeances")}
      </h4>
      <div className="mt-14 w-full max-w-[430px]">
        {/* <PrimaryButton
          icon={<FcGoogle size={24} />}
          width="100%"
          height="52px"
          outlined
          className="mt-6 md:mt-14"
        >
          {translate("ns1:Auth.ContinueWithGoogle")}
        </PrimaryButton>

        <HorizontalDivider gap={isDesktop ? "40px" : "20px"} /> */}

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="mb-4 md:mb-8"
        >
          <INInput
            error={errors.email}
            label={translate("ns1:Input.Labels.Email")}
            placeholder="mail@exemple.com"
            register={{
              ...register("email", {
                required: translate("ns1:Input.Errors.Required"),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: translate("ns1:Input.Errors.EmailFormat"),
                },
              }),
            }}
          />
        </form>

        <PrimaryButton
          disabled={!isValid}
          icon={<HiMail size={20} />}
          width="100%"
          height="52px"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {translate("ns1:Auth.ContinueWithEmail")}
        </PrimaryButton>

        <div className="mt-6 text-center text-xs leading-5 text-darkGrey-600 md:mt-10">
          <Trans
            i18nKey={"Auth.TermsOfService" as never[] | (string & never[])}
            components={[
              <Link
                className="hover:underline"
                to="https://innizio.fr/cgu"
                target="_blank"
                rel="noopener noreferrer"
              />,
              <Link
                className="hover:underline"
                to="https://innizio.fr/politique-de-confidentialite"
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </div>
      </div>
    </div>
  );
}
