import { AxiosError } from "axios";
import { HttpService } from "data/http-service";
import {
  AuthManagement,
  LoginUser,
  UpdateUser,
  User,
  RegisterUser,
  PortailSession,
} from "domain/entities/auth.entities";
import { translate } from "ui/i18n";
import { Snackbar } from "ui/pages/snackbar";
import {
  getUserInfosDtoSchema,
  loginDtoSchema,
  portalSessionDtoSchema,
} from "data/auth/auth.dto.schemas";
import {
  removeSessionInLocalStorage,
  setSessionInLocalStorage,
} from "data/auth/auth-session-storage";
import { ParsedUser } from "domain/entities/auth.entities";
import { AuthGateway } from "domain/auth/auth.gateway";

export class AuthHttpGateway implements AuthGateway {
  async register(data: RegisterUser): Promise<{ success: boolean }> {
    const url = "register";
    return HttpService.post(url, data, {
      api: "connect",
      includeAuthorization: false,
    })
      .then((response) => {
        if (response.status !== 201) {
          Snackbar.showError({
            title: translate("ns1:Register.RegistrationError"),
          });
          return { success: false };
        }
        Snackbar.showSuccess({
          title: translate("ns1:Register.EmailSent"),
        });
        return { success: true };
      })
      .catch((error: AxiosError) => {
        if (error.response.status === 409) {
          Snackbar.showError({
            title: translate("ns1:Register.EmailAlreadyRegistered"),
          });
        } else {
          Snackbar.showError({
            title: translate("ns1:Register.RegistrationError"),
          });
        }
        return { success: false };
      });
  }

  async updateUser(user: UpdateUser): Promise<{ success: boolean }> {
    const url = `users/${user.userId}`;
    return HttpService.patch(url, user.data, {
      api: "connect",
      includeAuthorization: false,
    })
      .then((response) => {
        return { success: true };
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Register.UpdateUserError"),
        });
        return { success: false };
      });
  }

  async verifyUser(
    body: AuthManagement
  ): Promise<{ id: number | null; email: string | null }> {
    const url = "auth-management";
    return HttpService.post(url, body, {
      api: "connect",
      includeAuthorization: false,
    })
      .then((response) => {
        if (response.status === 201) {
          const parsedUser = getUserInfosDtoSchema.safeParse(response.data);
          return { id: parsedUser.data.id, email: parsedUser.data.email };
        } else {
          Snackbar.showError({
            title: translate("ns1:Register.TokenVerificationError"),
          });
          return { id: null, email: null };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Register.TokenVerificationError"),
        });
        return { id: null, email: null };
      });
  }

  async verifyEmail(body: AuthManagement): Promise<{ emailExists: boolean }> {
    const url = "auth-management";
    return HttpService.post(url, body, {
      api: "connect",
      includeAuthorization: false,
    })
      .then((response) => {
        return { emailExists: response.status !== 204 };
      })
      .catch((error: AxiosError) => {
        return { emailExists: true };
      });
  }

  async login(user: LoginUser): Promise<{
    userInfos: ParsedUser | null;
    success: boolean;
  }> {
    const url = "authentication";
    const errorResponse: {
      userInfos: ParsedUser | null;
      success: boolean;
    } = {
      userInfos: null,
      success: false,
    };
    return HttpService.post(
      url,
      { ...user, strategy: "local" },
      { api: "connect", includeAuthorization: false }
    )
      .then((response) => {
        if (response.status === 201) {
          const parsedUser = loginDtoSchema.safeParse(response.data);
          if (!parsedUser.success) return errorResponse;

          if (parsedUser.data.user.isVerified) {
            setSessionInLocalStorage({
              accessToken: parsedUser.data.accessToken,
            });
            HttpService.setAccessToken(parsedUser.data.accessToken);
          }
          return {
            userInfos: {
              ...parsedUser.data.user,
              newsletter: !!parsedUser.data.user.newsletter,
            },
            success: true,
          };
        } else return errorResponse;
      })
      .catch((error: AxiosError) => {
        return errorResponse;
      });
  }

  async resendVerifyUser(body: AuthManagement): Promise<{ success: boolean }> {
    const url = "auth-management";
    return HttpService.post(url, body, {
      api: "connect",
      includeAuthorization: false,
    })
      .then((response) => {
        Snackbar.showSuccess({
          title: translate("ns1:Register.EmailSent"),
        });
        return { success: true };
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Register.ErrorSendingEmail"),
        });
        return { success: false };
      });
  }

  async getUserInfos() {
    const url = "me";
    return HttpService.get(url, {
      api: "connect",
    })
      .then((response) => {
        if (response.status === 200) {
          const result = getUserInfosDtoSchema.safeParse(response.data);
          if (result.success === false) {
            Snackbar.showError({
              title: translate("ns1:Auth.ErrorGettingUserInfos"),
            });
            return { user: null };
          } else {
            const user: User = {
              id: result.data.id,
              email: result.data.email || "",
              firstName: result.data.firstName || "",
              lastName: result.data.lastName || "",
              jobRole: result.data.jobRole || "",
              newsletter: result.data.newsletter,
              isPremium: result.data.isPremium,
              isCustomer: result.data.isCustomer,
            };
            return { user };
          }
        } else {
          Snackbar.showError({
            title: translate("ns1:Auth.ErrorGettingUserInfos"),
          });
        }
      })
      .catch((error: AxiosError) => {
        if (error.response.status === 404) {
          removeSessionInLocalStorage();
          window.location.href = "/auth";
        }
        Snackbar.showError({
          title: translate("ns1:Auth.ErrorGettingUserInfos"),
        });
        return { user: null };
      });
  }

  async getPortalSession(): Promise<{
    session: PortailSession | null;
    success: boolean;
  }> {
    const url = `stripe/portal-session`;
    return HttpService.get(url, {
      api: "connect",
    })
      .then((response) => {
        if (response.status === 200) {
          const parsedSession = portalSessionDtoSchema.safeParse(response.data);
          if (parsedSession.success) {
            return {
              session: parsedSession.data,
              success: true,
            };
          } else return { session: null, success: false };
        } else {
          return {
            session: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        return { session: null, success: false };
      });
  }

  async sendResetPassword(email: string): Promise<{
    success: boolean;
  }> {
    const url = `auth-management`;
    return HttpService.post(
      url,
      {
        action: "sendResetPwd",
        value: {
          email,
        },
      },
      {
        api: "connect",
      }
    )
      .then((response) => {
        if (response.status === 201) {
          return { success: true };
        } else return { success: false };
      })
      .catch((error: AxiosError) => {
        return { success: false };
      });
  }

  async sendNewPassword(body: { token: string; password: string }): Promise<{
    success: boolean;
  }> {
    const url = `auth-management`;
    return HttpService.post(
      url,
      {
        action: "resetPwdLong",
        value: body,
      },
      {
        api: "connect",
      }
    )
      .then((response) => {
        if (response.status === 201) {
          return { success: true };
        } else return { success: false };
      })
      .catch((error: AxiosError) => {
        return { success: false };
      });
  }

  async updatePassword({
    email,
    oldPassword,
    password,
  }: {
    email: string;
    oldPassword: string;
    password: string;
  }): Promise<{
    success: boolean;
  }> {
    const url = `auth-management`;
    return HttpService.post(
      url,
      {
        action: "passwordChange",
        value: { user: { email }, oldPassword, password },
      },
      {
        api: "connect",
      }
    )
      .then((response) => {
        if (response.status === 201) {
          return { success: true };
        } else {
          Snackbar.showError({
            title: translate("ns1:Account.UpdatePasswordError"),
          });
          return { success: false };
        }
      })
      .catch((error: AxiosError) => {
        return { success: false };
      });
  }

  async updatePersonalInfos({
    data,
    userId,
  }: {
    data: {
      firstName: string;
      lastName: string;
      jobRole: string;
      newsletter: boolean | string;
    };
    userId: number;
  }): Promise<{
    success: boolean;
  }> {
    const url = `users/${userId}`;
    return HttpService.patch(url, data, {
      api: "connect",
    })
      .then((response) => {
        if (response.status === 200) {
          return { success: true };
        } else {
          Snackbar.showError({
            title: translate("ns1:Account.UpdateInfosError"),
          });
          return { success: false };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Account.UpdateInfosError"),
        });
        return { success: false };
      });
  }
}
