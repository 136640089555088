interface IProps {
  children?: React.ReactNode;
  onClick?: (event: any) => any;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  width?: string;
  height?: string;
  icon?: React.ReactNode;
  outlined?: boolean;
  className?: string;
}

export function PrimaryButton({
  children,
  onClick,
  disabled = false,
  type,
  width,
  height = "36px",
  icon,
  outlined,
  className,
}: IProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type || "button"}
      className={`flex items-center justify-center rounded-xl text-[14px] font-bold ${
        outlined
          ? "border border-darkGrey-800 text-darkGrey-800 hover:bg-darkGrey-200 disabled:border-darkGrey-500 disabled:bg-white disabled:text-darkGrey-500"
          : "bg-darkGrey-800 text-white hover:bg-darkGrey-700 disabled:bg-darkGrey-200"
      } ${className}`}
      style={{
        width,
        minHeight: height,
        padding: "8px 16px",
      }}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </button>
  );
}
