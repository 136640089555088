import {
  selectIsTechniqueLoading,
  selectTechnique,
  selectTechniqueAdvice,
} from "domain/techniques/techniques.selectors";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import {
  TechniqueContext,
  TechniqueType,
} from "domain/entities/techniques.entities";
import { Trans } from "react-i18next";
import { HiArrowLeft, HiHeart, HiOutlineHeart } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { translate } from "ui/i18n";
import { TechniqueBanner } from "ui/pages/app/techniques/technique/TechniqueBanner";
import { getDurationInHours } from "ui/utils/formatters";
import { SequenceComponent } from "ui/pages/app/techniques/technique/SequenceComponent";
import { Opinions } from "ui/pages/app/components/activities/Opinions";
import { SecondaryButton } from "ui/pages/app/components/SecondaryButton";
import { useWindowWidth } from "ui/utils/hooks";
import { Advice } from "ui/pages/app/components/activities/Advice";
import { Materiels } from "ui/pages/app/components/activities/Materiels";
import { useDisclosure } from "@chakra-ui/hooks";
import { selectFavoriteTechniques } from "domain/activities/activities.selectors";
import { toggleTechniqueFavorite } from "ui/utils/toggle-activity-favorite";

export function Technique() {
  const technique = useAppSelector(selectTechnique);
  const advice = useAppSelector(selectTechniqueAdvice);
  const isLoading = useAppSelector(selectIsTechniqueLoading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDesktop = useWindowWidth();
  const seeMoreOpinions = useDisclosure();
  const favoriteTechnique = useAppSelector(selectFavoriteTechniques).find(
    (t) => t.techniqueId === technique?.id
  );

  const handleFavoriteClick = async (e: any) => {
    e.stopPropagation();
    toggleTechniqueFavorite({
      favoriteActivity: favoriteTechnique,
      dispatch,
      activityId: technique.id,
    });
  };

  return (
    <div className="flex h-full flex-col p-4 md:flex-row md:py-8 md:pl-6 md:pr-10">
      <div className="my-4 flex w-full items-center justify-between md:my-0 md:w-12 md:items-start">
        <button
          onClick={() => navigate(-1)}
          className="z-50 flex h-5 items-center"
        >
          <HiArrowLeft size={24} className="text-darkGrey-800" />
        </button>
        {!isDesktop && (
          <div className="flex w-fit space-x-4">
            <button
              className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
              onClick={handleFavoriteClick}
            >
              {!!favoriteTechnique?.id ? (
                <HiHeart className="text-secondaryGrey-900" size={20} />
              ) : (
                <HiOutlineHeart className="text-secondaryGrey-900" size={20} />
              )}
            </button>
            {/* <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
              <HiDownload className="text-secondaryGrey-900" size={20} />
            </button> */}
          </div>
        )}
      </div>

      {technique && !isLoading ? (
        <>
          <div className="flex w-full flex-col space-y-6 md:space-y-8">
            <TechniqueBanner
              technique={technique}
              isFavorite={!!favoriteTechnique?.id}
              handleFavoriteClick={handleFavoriteClick}
            />

            <div className="flex flex-wrap gap-x-3 gap-y-3 md:justify-start">
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-700 px-3 text-xs font-semibold text-white">
                {translate(`ns1:Techniques.${technique.type as TechniqueType}`)}
              </div>
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                {translate(
                  `ns1:Techniques.${technique.context as TechniqueContext}`
                )}
              </div>
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                {getDurationInHours(Number(technique.duration))}
              </div>
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                {technique.onsite &&
                  technique.remote &&
                  translate("ns1:Activities.remote&onsite")}
                {technique.onsite &&
                  !technique.remote &&
                  translate("ns1:Activities.onsite")}
                {!technique.onsite &&
                  technique.remote &&
                  translate("ns1:Activities.remote")}
              </div>
              {technique.participants ? (
                <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                  <Trans
                    i18nKey={
                      "Activities.UntilParticipants" as
                        | never[]
                        | (string & never[])
                    }
                    values={{ participants: technique.participants }}
                  />
                </div>
              ) : (
                <></>
              )}
              {technique.participants === 0 && (
                <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                  <Trans
                    i18nKey={
                      "Activities.MoreThanParticipants" as
                        | never[]
                        | (string & never[])
                    }
                    values={{ participants: 20 }}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col space-x-0 space-y-6 md:flex-row md:space-x-8 md:space-y-0">
              <div className="flex w-full flex-col space-y-6 md:space-y-8">
                <div className="rounded-[30px] bg-white p-4 py-6 md:p-6">
                  <span className="text-base font-medium md:text-sm">
                    {`${translate("ns1:Activities.Goal")}: ${
                      technique.objectif
                    }`}
                  </span>
                  {technique.sequences.length > 0 &&
                    technique.sequences.map((sequence, index) => (
                      <SequenceComponent
                        key={"sequence" + index}
                        sequence={sequence}
                        index={index}
                        sequencesLength={technique.sequences.length}
                      />
                    ))}
                </div>

                {/* advice and equipment for mobile */}
                {!isDesktop && (advice || technique.materiels.length > 0) && (
                  <div className="flex flex-col space-y-6 md:space-y-8">
                    {technique.materiels.length > 0 &&
                      technique.materiels[0].id && (
                        <Materiels materiels={technique.materiels} />
                      )}
                    {advice && <Advice advice={advice} />}
                  </div>
                )}

                <div className="rounded-[30px] bg-white p-4 py-6 md:p-6">
                  <div className="flex flex-col items-center justify-center space-y-1.5">
                    {technique.testimonials?.length > 0 ? (
                      <>
                        <p className="text-xl font-bold text-secondaryGrey-900">
                          <Trans
                            i18nKey={
                              "Technique.NumberOfReviews" as
                                | never[]
                                | (string & never[])
                            }
                            values={{
                              reviews: technique.testimonials.length,
                            }}
                          />
                        </p>
                        <p className="text-sm font-medium text-secondaryGrey-800">
                          {translate("ns1:Technique.ForThisTechnique")}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-3xl font-bold text-secondaryGrey-900">
                          {translate("ns1:Technique.NoReviewsYet")}
                        </p>
                        <p className="text-base font-medium text-secondaryGrey-800">
                          {translate("ns1:Technique.ForThisTechnique")}
                        </p>
                      </>
                    )}
                  </div>

                  <Opinions
                    testimonials={technique.testimonials.slice(
                      0,
                      seeMoreOpinions.isOpen ? technique.testimonials.length : 3
                    )}
                  />

                  {technique.testimonials.length > 3 && (
                    <div className="mt-16 flex flex-row justify-center space-x-6">
                      {/* <PrimaryButton height="36px">
                      {translate("ns1:Activities.Buttons.LeaveAReview")}
                    </PrimaryButton> */}
                      <SecondaryButton
                        height="36px"
                        onClick={seeMoreOpinions.onToggle}
                      >
                        {translate(
                          `ns1:Activities.Buttons.${
                            seeMoreOpinions.isOpen
                              ? "SeeLessReviews"
                              : "SeeMoreReviews"
                          }`
                        )}
                      </SecondaryButton>
                    </div>
                  )}
                </div>
              </div>

              {/* advice and equipment for desktop */}
              {isDesktop && (advice || technique.materiels.length > 0) && (
                <div className="flex w-80 flex-col space-y-6 md:space-y-8">
                  {technique.materiels.length > 0 &&
                    technique.materiels[0].id && (
                      <Materiels materiels={technique.materiels} />
                    )}
                  {advice && <Advice advice={advice} />}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <div className="loader" />
        </div>
      )}
    </div>
  );
}
