import { Seance } from "domain/entities/seances.entities";
import {
  selectIsSeanceLoading,
  selectSeanceTechniques,
} from "domain/seances/seances.selectors";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { BackButton } from "ui/pages/app/components/BackButton";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { ProgressionBar } from "ui/pages/app/components/ProgressionBar";
import { useWindowWidth } from "ui/utils/hooks";
import { TechniquesZone } from "ui/pages/app/seances/create-seance/components/TechniquesZone";
import { FinishFooter } from "ui/pages/app/seances/create-seance/components/FinishFooter";
import {
  SeanceTechnique,
  TechniqueContext,
} from "domain/entities/techniques.entities";
import { UpdateNote } from "ui/pages/app/seances/create-seance/steps/add-content/UpdateNote";
import { UpdatedObjective } from "ui/pages/app/seances/create-seance/steps/add-content/UpdateObjective";
import { useDisclosure } from "@chakra-ui/hooks";
import { ModalChooseTechType } from "ui/pages/app/seances/create-seance/steps/add-content/modals/ModalChooseTechType";
import { ModalChooseTechniques } from "ui/pages/app/seances/create-seance/steps/add-content/modals/ModalChooseTechniques";
import { ModalCreatePersonalStep } from "ui/pages/app/seances/create-seance/steps/add-content/modals/ModalCreatePersonalStep";
import {
  bulkInsertTechniques,
  bulkUpdateTechniques,
  insertTechnique,
  setSeanceActive,
} from "domain/seances/seances.actions";
import { Snackbar } from "ui/pages/snackbar";
import { useNavigate } from "react-router-dom";
import { PauseSuggestionZone } from "ui/pages/app/seances/create-seance/components/PauseSuggestionZone";
import { UpdateDuration } from "./UpdateDuration";
import { HorizontalDivider } from "ui/pages/app/components/HorizontalDivider";

interface IProps {
  handleBackButton: () => void;
  seanceId: number | null;
  seance: Seance;
  fetchSeance: () => Promise<void>;
}

export function AddContent({
  handleBackButton,
  seanceId,
  seance,
  fetchSeance,
}: IProps) {
  const isLoading = useAppSelector(selectIsSeanceLoading);
  const isDesktop = useWindowWidth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const techniques = useAppSelector(selectSeanceTechniques);
  const [selectedIntroTechniques, setSelectedIntroTechniques] = useState<
    SeanceTechnique[]
  >([]);
  const [selectedCoreTechniques, setSelectedCoreTechniques] = useState<
    SeanceTechnique[]
  >([]);
  const [selectedConclusionTechniques, setSelectedConclusionTechniques] =
    useState<SeanceTechnique[]>([]);
  const [selectedContext, setSelectedContext] = useState<TechniqueContext>();
  //for the sort move
  const [isMoving, setIsMoving] = useState(false);
  const [editDuration, setEditDuration] = useState(false);
  const [editObj, setEditObj] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const modalChooseTechType = useDisclosure();
  const modalChooseTechniques = useDisclosure();
  const modalCreatePersonalStep = useDisclosure();
  const pauseSuggestionZone = useDisclosure();

  //split seance's techniques to techniques arrays
  useEffect(() => {
    if (techniques) {
      const introTechniques: SeanceTechnique[] = [];
      const coreTechniques: SeanceTechnique[] = [];
      const conclusionTechniques: SeanceTechnique[] = [];
      techniques.forEach((t) => {
        if (t.context === "INTRO") {
          introTechniques.push(t);
        } else if (t.context === "CORE") {
          coreTechniques.push(t);
        } else if (t.context === "CONCLUSION") {
          conclusionTechniques.push(t);
        }
      });
      setSelectedIntroTechniques(introTechniques);
      setSelectedCoreTechniques(coreTechniques);
      setSelectedConclusionTechniques(conclusionTechniques);
    }
  }, [techniques]); //eslint-disable-line

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateSelectedTechniques();
    }, 100);
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [
    selectedCoreTechniques,
    selectedIntroTechniques,
    selectedConclusionTechniques,
  ]);

  useEffect(() => {
    if (
      !techniques.find((t) => t.technique.type === "pause") &&
      !pauseSuggestionZone.isOpen &&
      getCurrentDuration() > 120
    ) {
      pauseSuggestionZone.onOpen();
    }
    //eslint-disable-next-line
  }, [
    selectedCoreTechniques,
    selectedIntroTechniques,
    selectedConclusionTechniques,
  ]);

  const handleAddTechnique = (context: TechniqueContext) => {
    setSelectedContext(context);
    setTimeout(() => {
      modalChooseTechType.onOpen();
    });
  };

  const openChooseTechniquesModal = () => {
    modalChooseTechType.onClose();
    modalChooseTechniques.onOpen();
  };

  const openCreatePersonalStepModal = (technique?: SeanceTechnique) => {
    modalChooseTechType.onClose();
    modalCreatePersonalStep.onOpen();
  };

  const getCurrentDuration = () => {
    let total = 0;
    [
      ...selectedIntroTechniques,
      ...selectedCoreTechniques,
      ...selectedConclusionTechniques,
    ].forEach((t) => (total += Number(t.technique?.duration || 0)));
    return total;
  };

  const addTechniques = async (
    techniques: SeanceTechnique[],
    context: TechniqueContext
  ) => {
    let techniquesLength = 0;

    if (context === "INTRO") {
      techniquesLength = selectedIntroTechniques.length;
    } else if (context === "CORE") {
      techniquesLength =
        selectedIntroTechniques.length + selectedCoreTechniques.length;
    } else if (context === "CONCLUSION") {
      techniquesLength =
        selectedIntroTechniques.length +
        selectedCoreTechniques.length +
        selectedConclusionTechniques.length;
    }

    const result = await dispatch(
      bulkInsertTechniques({
        techniques: techniques.map((t, index) => {
          return {
            techniqueId: t.technique.id,
            context,
            order: techniquesLength + index,
          };
        }),
        seanceId: seance.id,
      })
    ).unwrap();

    if (result.success) {
      fetchSeance();
    }

    return result;
  };

  const addTechnique = async (
    technique: SeanceTechnique,
    context: TechniqueContext
  ) => {
    let techniquesLength = 0;

    if (context === "INTRO") {
      techniquesLength = selectedIntroTechniques.length;
    } else if (context === "CORE") {
      techniquesLength =
        selectedIntroTechniques.length + selectedCoreTechniques.length;
    } else if (context === "CONCLUSION") {
      techniquesLength =
        selectedIntroTechniques.length +
        selectedCoreTechniques.length +
        selectedConclusionTechniques.length;
    }

    const result = await dispatch(
      insertTechnique({
        technique: {
          techniqueId: technique.technique.id,
          context,
          order: techniquesLength,
        },
        seanceId: seance.id,
      })
    ).unwrap();

    if (result.success) {
      fetchSeance();
    }

    return result;
  };

  const updateSelectedTechniques = async () => {
    const result = await dispatch(
      bulkUpdateTechniques({
        techniques: [
          ...selectedIntroTechniques.map((t, index) => {
            return {
              id: t.id,
              context: "INTRO" as TechniqueContext,
              order: index,
            };
          }),
          ...selectedCoreTechniques.map((t, index) => {
            return {
              id: t.id,
              context: "CORE" as TechniqueContext,
              order: index,
            };
          }),
          ...selectedConclusionTechniques.map((t, index) => {
            return {
              id: t.id,
              context: "CONCLUSION" as TechniqueContext,
              order: index,
            };
          }),
        ],
        seanceId: seance.id,
      })
    ).unwrap();

    return result;
  };

  const saveSelectedTechniques = async (finish: boolean) => {
    if (
      !finish &&
      [
        ...selectedIntroTechniques,
        ...selectedCoreTechniques,
        ...selectedConclusionTechniques,
      ].length === 0
    ) {
      navigate("/my-creations");
    }

    const result = await updateSelectedTechniques();

    if (result.success && finish) {
      const resultSeanceActive = await dispatch(
        setSeanceActive(seance.id)
      ).unwrap();

      if (resultSeanceActive.success) {
        Snackbar.showSuccess({
          title: translate("ns1:CreateSeance.SeanceSaved"),
        });
        navigate("/my-creations");
      }
    } else if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:CreateSeance.SeanceSaved"),
      });
      navigate("/my-creations");
    }
  };

  return (
    <div className="min-h-dvh">
      <div className="flex h-full w-full flex-col items-center px-2 pt-4 md:px-12 md:pt-9">
        <div className="flex h-10 w-full items-center justify-between px-2 md:px-0">
          <BackButton onClick={handleBackButton} displayText={isDesktop} />
          {isDesktop && (
            <PrimaryButton
              onClick={() => saveSelectedTechniques(false)}
              disabled={isLoading}
            >
              {translate("ns1:CreateSeance.ContinueLater")}
            </PrimaryButton>
          )}
        </div>

        <div className="mt-8 flex w-full max-w-[780px] flex-col items-center justify-center">
          <h4 className="mb-6 w-fit text-center font-bold text-secondaryGrey-900">
            2. {translate("ns1:CreateSeance.AddContent")}
          </h4>
          <ProgressionBar percentage={50} />
        </div>

        <div className="my-8 mt-14 w-full rounded-2xl bg-white ">
          <UpdatedObjective
            seance={seance}
            seanceId={seanceId}
            fetchSeance={fetchSeance}
            isLoading={isLoading}
            editObj={editObj}
            setEditObj={setEditObj}
          />
          <HorizontalDivider gap="0" />
          <UpdateDuration
            seanceId={seanceId}
            fetchSeance={fetchSeance}
            currentDuration={getCurrentDuration()}
            seanceDuration={seance.duration}
            isLoading={isLoading}
            editDuration={editDuration}
            setEditDuration={setEditDuration}
          />
        </div>

        <div className="mt-8 flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
          <TechniquesZone
            context="INTRO"
            selectedTechniques={selectedIntroTechniques}
            setSelectedTechniques={setSelectedIntroTechniques}
            onAdd={handleAddTechnique}
            isMoving={isMoving}
            setIsMoving={setIsMoving}
            seanceId={seanceId}
            fetchSeance={fetchSeance}
          />
          <TechniquesZone
            context="CORE"
            selectedTechniques={selectedCoreTechniques}
            setSelectedTechniques={setSelectedCoreTechniques}
            onAdd={handleAddTechnique}
            isMoving={isMoving}
            setIsMoving={setIsMoving}
            seanceId={seanceId}
            fetchSeance={fetchSeance}
          />
          <TechniquesZone
            context="CONCLUSION"
            selectedTechniques={selectedConclusionTechniques}
            setSelectedTechniques={setSelectedConclusionTechniques}
            onAdd={handleAddTechnique}
            isMoving={isMoving}
            setIsMoving={setIsMoving}
            seanceId={seanceId}
            fetchSeance={fetchSeance}
          />
        </div>

        <UpdateNote
          seanceId={seanceId}
          fetchSeance={fetchSeance}
          note={seance?.note}
          isLoading={isLoading}
          editNote={editNote}
          setEditNote={setEditNote}
        />

        <ModalChooseTechType
          isOpen={modalChooseTechType.isOpen}
          onClose={modalChooseTechType.onClose}
          context={selectedContext}
          openChooseTechniquesModal={openChooseTechniquesModal}
          openCreatePersonalStepModal={openCreatePersonalStepModal}
        />

        <ModalChooseTechniques
          isOpen={modalChooseTechniques.isOpen}
          onClose={modalChooseTechniques.onClose}
          selectedDuration={Number(seance?.duration)}
          addTechniques={addTechniques}
          context={selectedContext}
          participants={seance.participants}
          modality={seance.onsite ? "onsite" : "remote"}
          currentDuration={getCurrentDuration()}
          onOpen={modalChooseTechniques.onOpen}
        />

        <ModalCreatePersonalStep
          isOpen={modalCreatePersonalStep.isOpen}
          onClose={modalCreatePersonalStep.onClose}
          context={selectedContext}
          addTechnique={addTechnique}
        />
      </div>

      {pauseSuggestionZone.isOpen && (
        <PauseSuggestionZone
          onClose={pauseSuggestionZone.onClose}
          addTechnique={addTechnique}
        />
      )}

      {!pauseSuggestionZone.isOpen &&
        !editDuration &&
        !editNote &&
        !editObj && (
          <FinishFooter
            onSave={() => saveSelectedTechniques(true)}
            onSaveContinue={() => saveSelectedTechniques(false)}
            isLoading={isLoading}
            canSave={
              selectedIntroTechniques.length +
                selectedCoreTechniques.length +
                selectedConclusionTechniques.length >
              0
            }
          />
        )}
    </div>
  );
}
