import { getFiltersFromLocalStorage } from "data/activities/activities-session-storage";
import {
  ActivitiesPageFilters,
  DurationFilter,
} from "domain/entities/activities.entities";
import {
  CreatePersonalStep,
  CreateSeance,
  InsertSeanceTechnique,
  UpdateSeanceTechnique,
} from "domain/entities/seances.entities";
import { createAppAsyncThunk } from "domain/store.thunk";

export const getSeances = createAppAsyncThunk(
  "seances/getSeances",
  async (
    filters: Partial<ActivitiesPageFilters> &
      Partial<{ duration: DurationFilter }>,
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.getSeances(filters);
  }
);

export const getSeance = createAppAsyncThunk(
  "seances/getSeance",
  async (id: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.getSeance(id);
  }
);

export const getSeanceAdvice = createAppAsyncThunk(
  "seances/getSeanceAdvice",
  async (id: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.getSeanceAdvice(id);
  }
);

export const createSeance = createAppAsyncThunk(
  "seances/createSeance",
  async (seance: CreateSeance, { extra: { seancesGateway } }) => {
    return await seancesGateway.createSeance(seance);
  }
);

export const removeSeanceAdvice = createAppAsyncThunk(
  "seances/removeSeanceAdvice",
  async () => {
    return;
  }
);

export const updateSeance = createAppAsyncThunk(
  "seances/updateSeance",
  async (
    infos: { seance: CreateSeance; id: number },
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.updateSeance(infos);
  }
);

export const createPersonalStep = createAppAsyncThunk(
  "seances/createPersonalStep",
  async (technique: CreatePersonalStep, { extra: { seancesGateway } }) => {
    return await seancesGateway.createPersonalStep(technique);
  }
);

export const updatePersonalStep = createAppAsyncThunk(
  "seances/updatePersonalStep",
  async (
    body: { id: number; technique: CreatePersonalStep },
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.updatePersonalStep(body);
  }
);

export const bulkInsertTechniques = createAppAsyncThunk(
  "seances/bulkInsertTechniques",
  async (
    body: {
      techniques: InsertSeanceTechnique[];
      seanceId: number;
    },
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.bulkInsertTechniques(body);
  }
);

export const insertTechnique = createAppAsyncThunk(
  "seances/insertTechnique",
  async (
    body: {
      technique: InsertSeanceTechnique;
      seanceId: number;
    },
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.insertTechnique(body);
  }
);

export const bulkUpdateTechniques = createAppAsyncThunk(
  "seances/bulkUpdateTechniques",
  async (
    body: {
      techniques: UpdateSeanceTechnique[];
      seanceId: number;
    },
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.bulkUpdateTechniques(body);
  }
);

export const setSeanceActive = createAppAsyncThunk(
  "seances/setSeanceActive",
  async (seanceId: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.setSeanceActive(seanceId);
  }
);

export const deleteSeanceTechnique = createAppAsyncThunk(
  "seances/deleteSeanceTechnique",
  async (
    body: { seanceId: number; techniqueId: number },
    { extra: { seancesGateway } }
  ) => {
    return await seancesGateway.deleteSeanceTechnique(body);
  }
);

export const getSeanceTechniques = createAppAsyncThunk(
  "seances/getSeanceTechniques",
  async (seanceId: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.getSeanceTechniques(seanceId);
  }
);

export const getSeanceMateriels = createAppAsyncThunk(
  "seances/getSeanceMateriels",
  async (id: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.getSeanceMateriels(id);
  }
);

export const deleteSeance = createAppAsyncThunk(
  "seances/deleteSeance",
  async (seanceId: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.deleteSeance(seanceId);
  }
);

export const duplicateSeance = createAppAsyncThunk(
  "seances/duplicateSeance",
  async (seanceId: number, { extra: { seancesGateway } }) => {
    return await seancesGateway.duplicateSeance(seanceId);
  }
);

export const getFavoritesSeances = createAppAsyncThunk(
  "seances/getFavoritesSeances",
  async (seanceIds: number[], { extra: { seancesGateway } }) => {
    return await seancesGateway.getFavoritesSeances(seanceIds);
  }
);

export const getSavedSeanceFilters = createAppAsyncThunk(
  "techniques/getSavedSeanceFilters",
  async (_, { extra: { seancesGateway } }) => {
    return getFiltersFromLocalStorage("seanceFilters");
  }
);
