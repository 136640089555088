import { Control, FieldError, useController } from "react-hook-form";
import TimePicker from "react-time-picker";
import { translate } from "ui/i18n";
import { Inputs } from "ui/pages/inputsType";

interface IProps {
  label?: string;
  control: Control<Inputs, any>;
  inputName: string;
  required?: boolean;
  error?: FieldError;
  defaultValue: string;
  className?: string;
  optional?: boolean;
}

export function INDurationField({
  label,
  control,
  inputName,
  required,
  error,
  defaultValue,
  className,
}: IProps) {
  const { field } = useController({
    name: inputName,
    control,
    rules: {
      required: required
        ? translate("ns1:Input.Errors.SelectADuration")
        : false,
    },
    defaultValue,
  });

  return (
    <div className={className}>
      {label && (
        <label className="w-full text-sm font-bold leading-6 text-darkGrey-700">
          {label}
          {!required && (
            <span className="ms-1 text-xs font-medium leading-4">
              ({translate("ns1:Input.Optional")})
            </span>
          )}
        </label>
      )}
      <TimePicker
        onChange={field.onChange}
        value={field.value || "00:00"}
        disableClock
        clearIcon={null}
        className={`${label ? "mt-2" : ""} ${error ? "duration-error" : ""}`}
        hourPlaceholder="hh"
        minutePlaceholder="mm"
      />
      {error && error.message && (
        <p className="mt-1 text-sm font-medium text-error">{error.message}</p>
      )}
    </div>
  );
}
