import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { CloseButton } from "ui/pages/app/components/CloseButton";
import StripePricingTable from "ui/pages/app/account/components/StripePricingTable";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalSubscriptionPricing({ isOpen, onClose }: IProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="750px"
        margin="20px"
        className="!rounded-[30px] p-4 md:p-8"
      >
        <CloseButton onClick={onClose} />
        <div className="mt-6 flex flex-col items-center">
          <StripePricingTable />
        </div>
      </ModalContent>
    </Modal>
  );
}
