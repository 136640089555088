import { HiDocument, HiFolderOpen, HiPencil } from "react-icons/hi";
import { translate } from "ui/i18n";
import { HomeCarousel } from "ui/pages/app/home/components/HomeCarousel";
import { HomeCardsCarousel } from "ui/pages/app/home/components/HomeCardsCarousel";
import { useAppSelector } from "ui/hook/store";
import {
  selectHomeConclusionTechniques,
  selectHomeCoreTechniques,
  selectHomeIntroTechniques,
} from "domain/techniques/techniques.selectors";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "ui/utils/hooks";
import { PageHeader } from "ui/pages/app/components/PageHeader";
import { selectIsPremium } from "domain/auth/auth.selectors";
import { Snackbar } from "ui/pages/snackbar";

export function Home() {
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();
  const homeIntroTechniques = useAppSelector(selectHomeIntroTechniques);
  const homeCoreTechniques = useAppSelector(selectHomeCoreTechniques);
  const homeConclusionTechniques = useAppSelector(
    selectHomeConclusionTechniques
  );
  const isPremium = useAppSelector(selectIsPremium);

  const handleCreateSeanceClick = () => {
    if (!isPremium) {
      Snackbar.showSubscriptionAlert(
        () => navigate("/account"),
        "create-seance"
      );
    } else navigate("/create-seance");
  };

  return (
    <div className="w-full px-4 pt-4 md:px-12 md:pt-8">
      <PageHeader title={translate("ns1:Routes.Home")} />

      <HomeCarousel />

      <div className="flex items-start justify-around space-x-2 py-6 md:justify-start md:space-x-8">
        <div className=" flex w-1/3 flex-col items-center justify-start text-center text-sm font-semibold md:w-auto">
          <button
            className="mb-2 flex items-center justify-center rounded-[12px] bg-white px-4 py-4 text-lg font-bold md:mb-0 md:rounded-[20px] md:px-6"
            onClick={() => navigate("/techniques")}
          >
            {isDesktop && translate("ns1:Home.AllTechniques")}{" "}
            <HiDocument className="md:ms-2" size={24} color="#2B3674" />
          </button>
          {!isDesktop && translate("ns1:Home.AllTechniques")}
        </div>
        <div className="flex w-1/3 flex-col items-center justify-start text-center text-sm font-semibold md:w-auto">
          <button
            className="mb-2 flex items-center justify-center rounded-[12px] bg-white px-4 py-4 text-lg font-bold md:mb-0 md:rounded-[20px] md:px-6"
            onClick={() => navigate("/seances")}
          >
            {isDesktop && translate("ns1:Home.AllSeances")}{" "}
            <HiFolderOpen className="md:ms-2" size={24} color="#2B3674" />
          </button>
          {!isDesktop && translate("ns1:Home.AllSeances")}
        </div>
        <div className="flex h-full w-1/3 flex-col items-center justify-start text-center text-sm font-semibold md:w-auto">
          <button
            className="mb-2 flex items-center rounded-[12px] bg-white px-4 py-4 text-lg font-bold md:mb-0 md:rounded-[20px] md:px-6"
            onClick={handleCreateSeanceClick}
          >
            {isDesktop && translate("ns1:Home.CreateASeance")}{" "}
            <HiPencil className="md:ms-2" size={24} color="#2B3674" />
          </button>
          {!isDesktop && translate("ns1:Home.CreateASeance")}
        </div>
      </div>

      <div className="mb-8">
        <HomeCardsCarousel
          title={translate("ns1:Techniques.INTRO")}
          techniques={homeIntroTechniques}
        />

        <HomeCardsCarousel
          title={translate("ns1:Techniques.CORE")}
          techniques={homeCoreTechniques}
        />

        <HomeCardsCarousel
          title={translate("ns1:Techniques.CONCLUSION")}
          techniques={homeConclusionTechniques}
        />

        {/* <HomeCardCarousel
          title="Séances pour se lancer"
          techniques={homeConclusionTechniques}
        /> */}
      </div>
    </div>
  );
}
