import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { translate } from "ui/i18n";
import { BackButton } from "ui/pages/app/components/BackButton";
import { Inputs } from "ui/pages/inputsType";
import { useWindowWidth } from "ui/utils/hooks";
import { INInput } from "ui/pages/app/components/INInput";
import { INRadioButtons } from "ui/pages/app/components/INRadioButtons";
import { INCheckbox } from "ui/pages/app/components/INCheckbox";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import {
  selectIsAuthenticationLoading,
  selectUserInfos,
} from "domain/auth/auth.selectors";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { jobOptions } from "ui/pages/auth/register/TellUsMore";
import { updatePersonalInfos } from "domain/auth/auth.actions";
import { Snackbar } from "ui/pages/snackbar";

export function ChangePersonalInfos() {
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();
  const isLoading = useAppSelector(selectIsAuthenticationLoading);
  const user = useAppSelector(selectUserInfos);
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState, control } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      lastName: user?.lastName || "",
      firstName: user?.firstName || "",
      jobRole: user?.jobRole || "",
      newsletter: user?.newsletter ? "true" : "false",
    },
  });
  const { errors, isValid, defaultValues } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    let newsletter = false;
    if (typeof data.newsletter === "string") {
      newsletter = data.newsletter === "true";
    } else newsletter = data.newsletter;

    const result = await dispatch(
      updatePersonalInfos({
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          jobRole: data.jobRole,
          newsletter,
        },
        userId: user?.id,
      })
    ).unwrap();

    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Account.UpdatedInfos"),
      });
    }
  };

  return (
    <div className="flex min-h-dvh flex-col bg-darkGrey-100 p-4 md:p-8">
      <BackButton
        onClick={() => navigate("/account")}
        displayText={isDesktop}
      />
      <div className="mt-5 flex w-full max-w-[700px] flex-col items-center justify-center self-center rounded-[20px] bg-white px-6 py-10 md:p-20">
        <h2 className="text-center">
          {translate("ns1:Account.ChangePersonalInfos")}
        </h2>

        <div className="w-full">
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="mt-6 md:mt-12"
          >
            <INInput
              error={errors.lastName}
              label={translate("ns1:Input.Labels.Name")}
              placeholder={translate("ns1:Input.Labels.LastName")}
              register={{
                ...register("lastName", {
                  required: translate("ns1:Input.Errors.Required"),
                }),
              }}
              className="mb-2 md:mb-4"
            />
            <INInput
              error={errors.firstName}
              label={translate("ns1:Input.Labels.FirstName")}
              placeholder={translate("ns1:Input.Labels.FirstName")}
              register={{
                ...register("firstName", {
                  required: translate("ns1:Input.Errors.Required"),
                }),
              }}
              className="mb-2 md:mb-4"
            />
            <INRadioButtons
              inputName="jobRole"
              options={jobOptions}
              label={translate("ns1:Input.Labels.WhatIsYourJob")}
              defaultValue={defaultValues.jobRole}
              className="mb-2 md:mb-4"
              error={errors.jobRole}
              control={control}
              required
            />

            <INCheckbox
              inputName="newsletter"
              control={control}
              text={translate("ns1:TellUsMore.IWouldLikeToBeInformed")}
              label={translate("ns1:Input.Labels.Preferences")}
            />

            <PrimaryButton
              className="mt-6 md:mt-20"
              type="submit"
              disabled={!isValid || isLoading}
              width="100%"
              height="52px"
              onClick={handleSubmit(handleFormSubmit)}
            >
              {translate("ns1:Button.Validate")}
            </PrimaryButton>
          </form>
        </div>
      </div>
    </div>
  );
}
