import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
} from "@chakra-ui/react";
import { HiCheck, HiChevronDown } from "react-icons/hi";
import { translate } from "ui/i18n";
import { INCheckbox } from "ui/pages/app/components/INCheckbox";
import {
  contextOfUseFilters,
  durationFilters,
  modalitiesFilters,
  objectiveFilters,
  typesFilters,
} from "ui/pages/app/components/activities/activitiesFiltersValues";
import { INRangeSlider } from "ui/pages/app/components/INRangeSlider";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";
import { INRadioButton } from "ui/pages/app/components/INRadioButton";
import { useState } from "react";
import { INInput } from "ui/pages/app/components/INInput";

interface IProps {
  filters: ActivitiesPageFilters;
  setFilters: (
    filters: ActivitiesPageFilters & {
      [key: string]: string | string[] | boolean | DurationFilter;
    }
  ) => void;
  fetchActivities?: () => void;
  activitiesType: "techniques" | "seances";
  maxDuration?: number;
  minDuration?: number;
  className?: string;
  resetFilters: () => void;
}

export function DesktopActivitiesFilters({
  filters,
  setFilters,
  fetchActivities,
  activitiesType,
  maxDuration,
  minDuration,
  className,
  resetFilters,
}: IProps) {
  const [isContextOfUseOpen, setIsContextOfUseOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isObjectiveOpen, setIsObjectiveOpen] = useState(false);
  const [isDurationOpen, setIsDurationOpen] = useState(false);
  const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);
  const [isModalityOpen, setIsModalityOpen] = useState(false);

  const focusParticipants = () => {
    setTimeout(
      () => document.getElementById("participants-input")?.focus(),
      100
    );
  };

  return (
    <div>
      <div className={`mt-8 flex space-x-4 ${className || ""}`}>
        {/* ContextOfUse filter */}
        {activitiesType !== "seances" && (
          <Popover
            isOpen={isContextOfUseOpen}
            onOpen={() => setIsContextOfUseOpen(true)}
            onClose={() => setIsContextOfUseOpen(false)}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <button className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold">
                {filters.contexts?.length > 0 && (
                  <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                    {filters.contexts?.length}
                  </span>
                )}
                {translate("ns1:Activities.Buttons.ContextOfUse")}
                <HiChevronDown
                  size={22}
                  className={`ms-1 ${
                    isContextOfUseOpen ? "popover-open" : "popover-close"
                  }`}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent
              style={{
                isolation: "isolate",
                zIndex: 100,
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="flex flex-col space-y-4 rounded-[20px] bg-white p-5 shadow-lg">
                {contextOfUseFilters.map((filter) => (
                  <INCheckbox
                    key={filter.value}
                    inputName={filter.value}
                    text={filter.text}
                    value={filters.contexts?.includes(filter.value)}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        contexts: filters.contexts?.includes(filter.value)
                          ? filters.contexts.filter((c) => c !== filter.value)
                          : [...filters.contexts, filter.value],
                      })
                    }
                    bold
                  />
                ))}
                <button
                  className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
                  onClick={() =>
                    setFilters({
                      ...filters,
                      contexts: [],
                    })
                  }
                >
                  {translate("ns1:Button.Reset")}
                </button>
              </div>
            </PopoverContent>
          </Popover>
        )}

        {/* Type filter */}
        {activitiesType !== "seances" && (
          <Popover
            isOpen={isTypeOpen}
            onOpen={() => setIsTypeOpen(true)}
            onClose={() => setIsTypeOpen(false)}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <button className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold">
                {filters.types?.length > 0 && (
                  <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                    {filters.types?.length}
                  </span>
                )}
                {translate("ns1:Activities.Buttons.Type")}
                <HiChevronDown
                  size={22}
                  className={`ms-1 ${
                    isTypeOpen ? "popover-open" : "popover-close"
                  }`}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent
              style={{
                isolation: "isolate",
                zIndex: 100,
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="flex flex-col space-y-4 rounded-[20px] bg-white p-5 shadow-lg">
                {typesFilters.map((filter) => (
                  <INCheckbox
                    key={filter.value}
                    inputName={filter.value}
                    text={filter.text}
                    value={filters.types?.includes(filter.value)}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        types: filters.types?.includes(filter.value)
                          ? filters.types.filter((c) => c !== filter.value)
                          : [...filters.types, filter.value],
                      })
                    }
                    bold
                  />
                ))}
                <button
                  className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
                  onClick={() =>
                    setFilters({
                      ...filters,
                      types: [],
                    })
                  }
                >
                  {translate("ns1:Button.Reset")}
                </button>
              </div>
            </PopoverContent>
          </Popover>
        )}

        {/* Objective filter */}
        {activitiesType !== "seances" && (
          <Popover
            isOpen={isObjectiveOpen}
            onOpen={() => setIsObjectiveOpen(true)}
            onClose={() => setIsObjectiveOpen(false)}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <button className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold">
                {filters.objectives?.length > 0 && (
                  <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                    {filters.objectives?.length}
                  </span>
                )}
                {translate("ns1:Activities.Buttons.Objective")}
                <HiChevronDown
                  size={22}
                  className={`ms-1 ${
                    isObjectiveOpen ? "popover-open" : "popover-close"
                  }`}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent
              style={{
                isolation: "isolate",
                zIndex: 100,
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="flex flex-col space-y-4 rounded-[20px] bg-white p-5 shadow-lg">
                {objectiveFilters.map((filter) => (
                  <INCheckbox
                    key={filter.value}
                    inputName={filter.value}
                    text={filter.text}
                    value={filters.objectives?.includes(filter.value)}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        objectives: filters.objectives?.includes(filter.value)
                          ? filters.objectives.filter((c) => c !== filter.value)
                          : [...filters.objectives, filter.value],
                      })
                    }
                    bold
                  />
                ))}
                <button
                  className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
                  onClick={() =>
                    setFilters({
                      ...filters,
                      objectives: [],
                    })
                  }
                >
                  {translate("ns1:Button.Reset")}
                </button>
              </div>
            </PopoverContent>
          </Popover>
        )}

        {/* Duration filter */}
        {activitiesType !== "seances" ? (
          <Popover
            isOpen={isDurationOpen}
            onOpen={() => setIsDurationOpen(true)}
            onClose={() => setIsDurationOpen(false)}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <button className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold">
                {(filters.duration?.$gte !== minDuration ||
                  filters.duration?.$lte !== maxDuration) && (
                  <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                    <HiCheck color="white" size="12" />
                  </span>
                )}
                {translate("ns1:Activities.Buttons.Duration")}
                <HiChevronDown
                  size={22}
                  className={`ms-1 ${
                    isDurationOpen ? "popover-open" : "popover-close"
                  }`}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent
              style={{
                isolation: "isolate",
                zIndex: 100,
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className=" rounded-[20px] bg-white p-5 shadow-lg">
                <INRangeSlider
                  min={minDuration}
                  max={maxDuration}
                  duration={filters.duration}
                  setDuration={(duration) =>
                    setFilters({
                      ...filters,
                      duration,
                    })
                  }
                  width={200}
                  step={5}
                  fetchActivities={fetchActivities}
                  resetButton
                />
              </div>
            </PopoverContent>
          </Popover>
        ) : (
          <Popover
            isOpen={isDurationOpen}
            onOpen={() => setIsDurationOpen(true)}
            onClose={() => setIsDurationOpen(false)}
            placement="bottom-start"
          >
            <PopoverTrigger>
              <button className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold">
                {filters.selectedDuration !== "" && (
                  <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                    <HiCheck color="white" size="12" />
                  </span>
                )}
                {translate("ns1:Activities.Buttons.Duration")}
                <HiChevronDown
                  size={22}
                  className={`ms-1 ${
                    isDurationOpen ? "popover-open" : "popover-close"
                  }`}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent
              style={{
                isolation: "isolate",
                zIndex: 100,
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="rounded-[20px] bg-white p-5 shadow-lg">
                <RadioGroup
                  onChange={(value) =>
                    setFilters({ ...filters, selectedDuration: value })
                  }
                  value={filters.selectedDuration}
                  className="flex flex-col space-y-4"
                >
                  {durationFilters.map((filter) => (
                    <INRadioButton
                      key={filter.value}
                      text={filter.text}
                      value={filter.value}
                      selectedValue={filters.selectedDuration}
                    />
                  ))}
                </RadioGroup>
                <button
                  className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
                  onClick={() =>
                    setFilters({ ...filters, selectedDuration: "" })
                  }
                >
                  {translate("ns1:Button.Reset")}
                </button>
              </div>
            </PopoverContent>
          </Popover>
        )}

        {/* Participants filter */}
        <Popover
          isOpen={isParticipantsOpen}
          onOpen={() => setIsParticipantsOpen(true)}
          onClose={() => setIsParticipantsOpen(false)}
          placement="bottom-start"
        >
          <PopoverTrigger>
            <button
              className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold"
              onClick={focusParticipants}
            >
              {filters.participants && (
                <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                  <HiCheck color="white" size="12" />
                </span>
              )}
              {translate("ns1:Activities.Buttons.Participants")}
              <HiChevronDown
                size={22}
                className={`ms-1 ${
                  isParticipantsOpen ? "popover-open" : "popover-close"
                }`}
              />
            </button>
          </PopoverTrigger>
          <PopoverContent
            style={{
              isolation: "isolate",
              zIndex: 100,
              width: "fit-content",
              border: "none",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="rounded-[20px] bg-white p-5 shadow-lg">
              <INInput
                value={filters.participants}
                onChange={(value) =>
                  setFilters({ ...filters, participants: value })
                }
                placeholder="Ex: 10"
                type="number"
                min={4}
                max={50}
                id="participants-input"
                inputClassName="border-0 !p-0"
                height="30px"
              />
              <button
                className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
                onClick={() => setFilters({ ...filters, participants: "" })}
              >
                {translate("ns1:Button.Reset")}
              </button>
            </div>
          </PopoverContent>
        </Popover>

        {/* Modality filter */}
        <Popover
          isOpen={isModalityOpen}
          onOpen={() => setIsModalityOpen(true)}
          onClose={() => setIsModalityOpen(false)}
          placement="bottom-start"
        >
          <PopoverTrigger>
            <button className="flex items-center rounded-xl border border-darkGrey-200 bg-white px-[18px] py-3 text-sm font-bold">
              {filters.modalities?.length > 0 && (
                <span className="me-2 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs font-semibold text-white">
                  {filters.modalities?.length}
                </span>
              )}
              {translate("ns1:Activities.Buttons.Modality")}
              <HiChevronDown
                size={22}
                className={`ms-1 ${
                  isModalityOpen ? "popover-open" : "popover-close"
                }`}
              />
            </button>
          </PopoverTrigger>
          <PopoverContent
            style={{
              isolation: "isolate",
              zIndex: 100,
              width: "fit-content",
              border: "none",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="flex flex-col space-y-4 rounded-[20px] bg-white p-5 shadow-lg">
              {modalitiesFilters.map((filter) => (
                <INCheckbox
                  key={filter.value}
                  inputName={filter.value}
                  text={filter.text}
                  value={filters.modalities?.includes(filter.value)}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      modalities: filters.modalities?.includes(filter.value)
                        ? filters.modalities.filter((c) => c !== filter.value)
                        : [...filters.modalities, filter.value],
                    })
                  }
                  bold
                />
              ))}
              <button
                className="mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
                onClick={() =>
                  setFilters({
                    ...filters,
                    modalities: [],
                  })
                }
              >
                {translate("ns1:Button.Reset")}
              </button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <button
        className="ms-2 mt-4 w-fit text-sm font-bold text-darkGrey-700 underline"
        onClick={resetFilters}
      >
        {translate("ns1:Button.ResetFilters")}
      </button>
    </div>
  );
}
