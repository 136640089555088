import {
  selectIsTechniquesLoading,
  selectTechniques,
} from "domain/techniques/techniques.selectors";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { TechniqueCard } from "ui/pages/app/components/activities/TechniqueCard";
import { DesktopActivitiesFilters } from "ui/pages/app/components/activities/DesktopActivitiesFilters";
import { PageHeader } from "ui/pages/app/components/PageHeader";
import { useWindowWidth } from "ui/utils/hooks";
import { HiAdjustments } from "react-icons/hi";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";
import {
  getSavedTechniqueFilters,
  getTechniques,
} from "domain/techniques/techniques.actions";
import { MobileActivitiesFilters } from "ui/pages/app/components/activities/MobileActivitiesFilters";
import { useNavigate } from "react-router-dom";
import { getFiltersCount } from "ui/utils/get-filters-count";
import { setFiltersInLocalStorage } from "data/activities/activities-session-storage";
import { INSearchField } from "ui/pages/app/components/INSearchField";
import { Technique } from "domain/entities/techniques.entities";
import { Snackbar } from "ui/pages/snackbar";
import { selectIsPremium } from "domain/auth/auth.selectors";

export function Techniques() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsTechniquesLoading);
  const isPremium = useAppSelector(selectIsPremium);
  const techniques = useAppSelector(selectTechniques);
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();
  const minDuration = 0;
  const maxDuration = 180;
  const [search, setSearch] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<
    ActivitiesPageFilters & {
      [key: string]: string[] | string | boolean | number | DurationFilter;
    }
  >({
    contexts: [],
    types: [],
    objectives: [],
    participants: "",
    modalities: [],
    duration: {
      $gte: minDuration,
      $lte: maxDuration,
    },
  });

  const fetchTechniques = () => {
    setFiltersInLocalStorage("techniqueFilters", filters);
    dispatch(
      getTechniques({
        pauses: false,
        search,
        isInniz: true,
        ...filters,
      })
    );
  };

  const getSavedFilters = async () => {
    const savedFilters = await dispatch(getSavedTechniqueFilters()).unwrap();
    if (savedFilters) {
      setFilters(savedFilters);
    }
  };

  useEffect(() => {
    getSavedFilters();
  }, []); //eslint-disable-line

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchTechniques();
    }, 500);
    return () => clearTimeout(timeout);
  }, [filters, search]); //eslint-disable-line

  const handleCardClick = async (technique: Technique) => {
    if (!isPremium && !!technique.premium) {
      await Snackbar.removeSubscriptionAlert();
      Snackbar.showSubscriptionAlert(() => navigate("/account"), "technique");
    } else navigate(`/technique/${technique?.id}`);
  };

  const resetFilters = () => {
    setFilters({
      contexts: [],
      types: [],
      objectives: [],
      duration: {
        $gte: minDuration,
        $lte: maxDuration,
      },
      participants: "",
      modalities: [],
    });
  };

  return (
    <div
      className={`flex flex-row overflow-x-hidden ${
        isFiltersOpen ? "filters-open" : "filters-closed"
      }`}
      style={{ width: isDesktop ? "100%" : "200vw" }}
    >
      <div className="w-screen px-2 pt-2 md:px-12 md:pt-8">
        <PageHeader
          search={search}
          setSearch={setSearch}
          title={translate("ns1:Routes.Techniques")}
          placeholder="TechniqueName"
        />

        {isDesktop ? (
          <DesktopActivitiesFilters
            filters={filters}
            setFilters={setFilters}
            fetchActivities={fetchTechniques}
            activitiesType="techniques"
            minDuration={minDuration}
            maxDuration={maxDuration}
            resetFilters={resetFilters}
          />
        ) : (
          <div className="mt-7 flex">
            <INSearchField
              placeholder={translate("ns1:Home.TechniqueName")}
              value={search}
              setValue={setSearch}
              width="100%"
              height="48px"
            />
            <div className="ms-3 h-12 w-12">
              <button
                className="relative flex h-12  w-12 items-center justify-center rounded-full border border-darkGrey-200 bg-white py-2 text-base font-bold"
                onClick={() => setIsFiltersOpen(true)}
              >
                <HiAdjustments color="#2B3674" size="24" />
                <span
                  className="absolute flex h-4 w-4 items-center justify-center rounded-full bg-pink-400 text-[9px] text-white"
                  style={{ top: "-2px", right: "-2px" }}
                >
                  {getFiltersCount(filters, minDuration, maxDuration)}
                </span>
              </button>
            </div>
          </div>
        )}

        <div className="mb-8 mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {!isLoading ? (
            <>
              {techniques.map((technique, index) => (
                <TechniqueCard
                  technique={technique}
                  key={"technique" + index}
                  onClick={() => handleCardClick(technique)}
                />
              ))}
            </>
          ) : (
            <>
              {[...Array(12)].map((_, index) => (
                <INSkeleton key={"techniques-skeleton" + index} />
              ))}
            </>
          )}
        </div>
      </div>
      {!isDesktop && (
        <MobileActivitiesFilters
          filters={filters}
          setFilters={setFilters}
          fetchActivities={fetchTechniques}
          activitiesType="techniques"
          minDuration={minDuration}
          maxDuration={maxDuration}
          setIsFiltersOpen={setIsFiltersOpen}
          resetFilters={resetFilters}
        />
      )}
    </div>
  );
}
