export function BgLightGreen500() {
  return (
    <svg
      className="rounded-[10px]"
      viewBox="0 0 311 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_968_12216)">
        <rect width="311" height="178" fill="#53BBAD" />
        <g clipPath="url(#clip1_968_12216)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M426.742 -36.7704C430.312 -40.6851 436.375 -40.9598 440.283 -37.384C448.459 -29.9045 455.964 -18.6502 461.776 -7.13963C467.623 4.43765 472.219 17.1899 474.06 28.1667C474.973 33.6116 475.307 39.1898 474.426 44.1898C473.554 49.144 471.19 54.9341 465.473 58.3177C459.785 61.683 453.293 61.174 447.56 59.3137C441.766 57.4334 435.305 53.7475 428.143 48.4757C403.744 30.5173 381.045 28.8884 366.646 35.1622C352.864 41.1664 344.323 55.3461 348.868 75.8299C353.62 97.2481 355.661 113.75 354.892 126.166C354.124 138.564 350.389 148.84 341.549 154.811C333.177 160.466 322.712 160.461 313.074 159.045C303.18 157.591 291.712 154.232 279.765 150.643C262.956 145.592 251.148 149.305 244.528 155.094C237.79 160.985 235.317 169.806 238.084 178.121C239.758 183.151 237.044 188.588 232.022 190.264C227 191.941 221.572 189.223 219.898 184.193C214.605 168.291 219.552 151.445 231.921 140.63C244.407 129.712 263.394 125.679 285.273 132.252C297.507 135.928 307.599 138.834 315.857 140.048C324.372 141.299 328.652 140.364 330.829 138.893C332.538 137.739 335.156 134.694 335.758 124.977C336.359 115.279 334.786 100.874 330.154 79.9949C323.795 51.3382 336.069 27.5457 358.998 17.5559C381.309 7.83555 411.217 12.1908 439.495 33.0043C445.873 37.6982 450.44 40.0655 453.469 41.0486C454.332 41.3284 454.963 41.4651 455.405 41.5292C455.452 41.344 455.5 41.1207 455.547 40.8536C455.901 38.8473 455.88 35.6746 455.154 31.3462C453.717 22.7764 449.905 11.893 444.67 1.52602C439.401 -8.90764 433.149 -17.9063 427.354 -23.2079C423.446 -26.7836 423.171 -32.8558 426.742 -36.7704ZM455.12 42.3169C455.119 42.3154 455.129 42.2959 455.153 42.263C455.133 42.302 455.121 42.3184 455.12 42.3169ZM456.204 41.5736C456.236 41.5658 456.253 41.5639 456.253 41.5649C456.254 41.5659 456.238 41.5698 456.204 41.5736Z"
            fill="#9BECE1"
          />
          <path
            d="M248.458 52.9058C243.024 54.6621 237.198 51.6792 235.447 46.2432L230.179 29.8924C228.427 24.4564 231.413 18.6258 236.847 16.8695L253.148 11.6012C258.583 9.84482 264.408 12.8278 266.16 18.2638L271.428 34.6146C273.179 40.0506 270.194 45.8811 264.759 47.6375L248.458 52.9058Z"
            fill="#68D8C9"
          />
          <path
            d="M283.539 80.6765C278.595 77.8176 276.899 71.4889 279.751 66.541L282.339 62.0529C285.191 57.1049 291.511 55.4114 296.455 58.2703L300.923 60.8536C305.867 63.7125 307.563 70.0411 304.71 74.9891L302.123 79.4772C299.271 84.4251 292.951 86.1186 288.007 83.2597L283.539 80.6765Z"
            fill="#68D8C9"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_968_12216">
          <rect width="311" height="178" fill="white" />
        </clipPath>
        <clipPath id="clip1_968_12216">
          <rect
            width="287.558"
            height="240"
            fill="white"
            transform="matrix(-1 0 0 1 498.559 -45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
