import { IconType } from "react-icons";
import {
  HiDocument,
  HiFolderOpen,
  HiHeart,
  HiHome,
  HiPencil,
} from "react-icons/hi";
import { translate } from "ui/i18n";

interface SidebarRoute {
  path: string;
  icon: IconType;
  name: string;
  children?: { path: string; name: string }[];
}

export const sidebarRoutes: SidebarRoute[] = [
  {
    path: "/",
    icon: HiHome,
    name: translate("ns1:Routes.Home"),
  },
  {
    path: "/techniques",
    icon: HiDocument,
    name: translate("ns1:Routes.Techniques"),
  },
  {
    path: "/seances",
    icon: HiFolderOpen,
    name: translate("ns1:Routes.Seances"),
  },
  {
    path: "/favorites",
    icon: HiHeart,
    name: translate("ns1:Routes.Favorites"),
  },
  {
    path: "/my-creations",
    icon: HiPencil,
    name: translate("ns1:Routes.MyCreations"),
  },
];
