import { CarouselSlideType } from "domain/entities/carousel.entities";
import { z } from "zod";

export const carouselSlideDtoSchema = z.object({
  id: z.number(),
  innizRef: z.string().nullable(),
  title: z.string(),
  type: z.custom<CarouselSlideType>(),
  link: z.string(),
  active: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const carouselSlidesDtoSchema = z.array(carouselSlideDtoSchema);
