import ReactMarkdown from "react-markdown";

export const CustomMarkdown = ({ children }: { children: string }) => {
  const LinkRenderer = (props: any) => {
    return (
      <a
        className="hover:underline"
        href={props.href}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </a>
    );
  };

  const ParagraphRenderer = (props: any) => {
    return <span>{props.children}</span>;
  };

  const UListRenderer = (props: any) => {
    return <span>{props.children}</span>;
  };

  return (
    <ReactMarkdown
      components={{
        a: LinkRenderer,
        p: ParagraphRenderer,
        ul: UListRenderer,
        ol: UListRenderer,
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
