import { RootState } from "domain/store";

export const selectFavoriteTechniques = (state: RootState) => {
  return state.activities.favoriteTechniques;
};

export const selectFavoriteSeances = (state: RootState) => {
  return state.activities.favoriteSeances;
};

export const selectIsActivitiesLoading = (state: RootState) => {
  return state.activities.isActivitiesLoading;
};
