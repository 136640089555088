import { translate } from "ui/i18n";

export const contextOfUseFilters = [
  { value: "INTRO", text: translate("ns1:Techniques.INTRO") },
  { value: "CORE", text: translate("ns1:Techniques.CORE") },
  { value: "CONCLUSION", text: translate("ns1:Techniques.CONCLUSION") },
];

export const typesFilters = [
  { value: "ice_breaker", text: translate("ns1:Techniques.ice_breaker") },
  { value: "problematique", text: translate("ns1:Techniques.problematique") },
  { value: "equipes", text: translate("ns1:Techniques.equipes") },
  { value: "brainstorming", text: translate("ns1:Techniques.brainstorming") },
  { value: "projects", text: translate("ns1:Techniques.projects") },
  { value: "pratiques", text: translate("ns1:Techniques.pratiques") },
  {
    value: "experience_utilisateur",
    text: translate("ns1:Techniques.experience_utilisateur"),
  },
  {
    value: "evaluation",
    text: translate("ns1:Techniques.evaluation"),
  },
  { value: "vote", text: translate("ns1:Techniques.vote") },
  // { value: "pause", text: translate("ns1:Techniques.pause") },
];

export const objectiveFilters = [
  { value: "CADRER", text: translate("ns1:Techniques.Filters.CADRER") },
  {
    value: "RESOUDRE",
    text: translate("ns1:Techniques.Filters.RESOUDRE"),
  },
  { value: "COHESION", text: translate("ns1:Techniques.Filters.COHESION") },
  { value: "DYNAMIQUE", text: translate("ns1:Techniques.Filters.DYNAMIQUE") },
  { value: "ILLUSTRER", text: translate("ns1:Techniques.Filters.ILLUSTRER") },
  {
    value: "SYNTHETISER",
    text: translate("ns1:Techniques.Filters.SYNTHETISER"),
  },
  {
    value: "ACTION",
    text: translate("ns1:Techniques.Filters.ACTION"),
  },
];

// export const participantsFilters = [
//   {
//     value: "lte10",
//     text: translate("ns1:Techniques.Filters.UpTo10Participants"),
//   },
//   {
//     value: "lte15",
//     text: translate("ns1:Techniques.Filters.UpTo15Participants"),
//   },
//   {
//     value: "lte20",
//     text: translate("ns1:Techniques.Filters.UpTo20Participants"),
//   },
//   {
//     value: "gte20",
//     text: translate("ns1:Techniques.Filters.MoreThan20Participants"),
//   },
// ];

export const modalitiesFilters = [
  { value: "onsite", text: translate("ns1:Techniques.Filters.OnSite") },
  { value: "remote", text: translate("ns1:Techniques.Filters.Remote") },
];

export const durationFilters = [
  { value: "lte120", text: translate("ns1:Techniques.Filters.LessThan2H") },
  { value: "lte240", text: translate("ns1:Techniques.Filters.Between2Hand4H") },
  {
    value: "lte1440",
    text: translate("ns1:Techniques.Filters.Between4AndADay"),
  },
  { value: "gte1440", text: translate("ns1:Techniques.Filters.MoreThanADay") },
];
