export function HorizontalDivider({
  width = "100%",
  gap = "0",
}: {
  width?: string;
  gap?: string;
}) {
  return (
    <div
      className="border-b border-secondaryGrey-400"
      style={{ width, margin: `${gap} 0` }}
    />
  );
}
