import { createSlice } from "@reduxjs/toolkit";
import {
  ActivityAdvice,
  ActivityMateriel,
} from "domain/entities/activities.entities";
import { Seance } from "domain/entities/seances.entities";
import { SeanceTechnique } from "domain/entities/techniques.entities";
import {
  bulkInsertTechniques,
  bulkUpdateTechniques,
  createPersonalStep,
  createSeance,
  deleteSeance,
  deleteSeanceTechnique,
  duplicateSeance,
  getFavoritesSeances,
  getSeance,
  getSeanceAdvice,
  getSeanceMateriels,
  getSeances,
  getSeanceTechniques,
  insertTechnique,
  removeSeanceAdvice,
  setSeanceActive,
  updatePersonalStep,
  updateSeance,
} from "domain/seances/seances.actions";

export type SeancesState = {
  isSeancesLoading: boolean;
  seances: Seance[];
  isSeanceLoading: boolean;
  seance: Seance | null;
  advice: ActivityAdvice | null;
  seanceTechniques: SeanceTechnique[];
  materiels: ActivityMateriel[];
};

const initialState: SeancesState = {
  isSeancesLoading: false,
  seances: [],
  isSeanceLoading: false,
  seance: null,
  advice: null,
  seanceTechniques: [],
  materiels: [],
};

export const seancesSlice = createSlice({
  name: "seances",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //GET SEANCES
    builder.addCase(getSeances.fulfilled, (state, action) => {
      state.seances = action.payload.seances;
      state.isSeancesLoading = false;
    });
    builder.addCase(getSeances.pending, (state, action) => {
      state.isSeancesLoading = true;
    });
    //GET SEANCE
    builder.addCase(getSeance.fulfilled, (state, action) => {
      state.seance = action.payload.seance;
      state.isSeanceLoading = false;
    });
    builder.addCase(getSeance.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //GET ADVICE
    builder.addCase(getSeanceAdvice.fulfilled, (state, action) => {
      state.advice = action.payload.advice;
      state.isSeanceLoading = false;
    });
    builder.addCase(getSeanceAdvice.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //CREATE SEANCE
    builder.addCase(createSeance.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(createSeance.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //REMOVE ADVICE
    builder.addCase(removeSeanceAdvice.fulfilled, (state, action) => {
      state.advice = null;
    });
    //UPDATE SEANCE
    builder.addCase(updateSeance.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(updateSeance.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //CREATE PERSONAL STEP
    builder.addCase(createPersonalStep.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(createPersonalStep.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //UPDATE PERSONAL STEP
    builder.addCase(updatePersonalStep.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(updatePersonalStep.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //BULK INSERT SEANCE TECHNIQUES
    builder.addCase(bulkInsertTechniques.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(bulkInsertTechniques.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //INSERT SEANCE TECHNIQUE
    builder.addCase(insertTechnique.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(insertTechnique.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //UPDATE SEANCE TECHNIQUES
    builder.addCase(bulkUpdateTechniques.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(bulkUpdateTechniques.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //SET SEANCE ACTIVE
    builder.addCase(setSeanceActive.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(setSeanceActive.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //DELETE SEANCE TECHNIQUE
    builder.addCase(deleteSeanceTechnique.fulfilled, (state, action) => {
      state.isSeanceLoading = false;
    });
    builder.addCase(deleteSeanceTechnique.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //GET SEANCE TECHNIQUES
    builder.addCase(getSeanceTechniques.fulfilled, (state, action) => {
      state.seanceTechniques = action.payload.techniques;
      state.isSeanceLoading = false;
    });
    builder.addCase(getSeanceTechniques.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //GET MATERIELS
    builder.addCase(getSeanceMateriels.fulfilled, (state, action) => {
      state.materiels = action.payload.materiels;
      state.isSeanceLoading = false;
    });
    builder.addCase(getSeanceMateriels.pending, (state, action) => {
      state.isSeanceLoading = true;
    });
    //DELETE SEANCE
    builder.addCase(deleteSeance.fulfilled, (state, action) => {
      state.isSeancesLoading = false;
    });
    builder.addCase(deleteSeance.pending, (state, action) => {
      state.isSeancesLoading = true;
    });
    //DUPLICATE SEANCE
    builder.addCase(duplicateSeance.fulfilled, (state, action) => {
      state.isSeancesLoading = false;
    });
    builder.addCase(duplicateSeance.pending, (state, action) => {
      state.isSeancesLoading = true;
    });
    //GET FAVORITES SEANCES
    builder.addCase(getFavoritesSeances.fulfilled, (state, action) => {
      state.seances = action.payload.seances;
      state.isSeancesLoading = false;
    });
    builder.addCase(getFavoritesSeances.pending, (state, action) => {
      state.isSeancesLoading = true;
    });
  },
});
