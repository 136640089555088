import {
  SeanceTechnique,
  Technique,
  TechniqueContext,
} from "domain/entities/techniques.entities";
import { selectPauses } from "domain/techniques/techniques.selectors";
import { HiLightBulb, HiX } from "react-icons/hi";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { PauseCard } from "ui/pages/app/components/activities/PauseCard";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  onClose: () => void;
  addTechnique: (
    technique: SeanceTechnique,
    context: TechniqueContext
  ) => Promise<{
    success: boolean;
  }>;
}

export const PauseSuggestionZone = ({ onClose, addTechnique }: IProps) => {
  const isDesktop = useWindowWidth();
  const pauses = useAppSelector(selectPauses);

  const handleAddPause = async (pause: Technique) => {
    addTechnique({ technique: pause }, "CORE");
    onClose();
  };

  return (
    <div className="sticky bottom-0 z-[75] max-h-dvh w-full">
      <div className="relative max-h-dvh overflow-y-auto bg-darkGrey-700 px-2 py-4 shadow-lg md:px-12 md:px-6">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <HiLightBulb size={32} color="#fff" />
            <span className="ms-6 text-base font-bold text-white">
              {translate("ns1:CreateSeance.PauseSuggestions")}
            </span>
          </div>
          <button onClick={onClose}>
            <HiX size={24} color="#fff" />
          </button>
        </div>
        <div className="flex items-center">
          <div
            className={`flex w-full items-center ${
              !isDesktop
                ? "justify-between"
                : "justify-center gap-x-3 md:gap-x-6"
            }`}
          >
            <div className="mt-4 grid w-full grid-cols-1 gap-2 md:mt-8 md:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
              {pauses.map((pause, index) => (
                <PauseCard
                  technique={pause}
                  key={"pause" + index}
                  onClick={() => handleAddPause(pause)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
