import {
  ActivitiesPageFilters,
  DurationFilter,
} from "domain/entities/activities.entities";

type FiltersType = ActivitiesPageFilters & {
  [key: string]: string[] | string | boolean | number | DurationFilter;
};

type ActivityType = "seanceFilters" | "techniqueFilters";

export function setFiltersInLocalStorage(
  activityType: ActivityType,
  filters: FiltersType
) {
  localStorage.setItem(activityType, JSON.stringify(filters));
}

export function removeFiltersFromLocalStorage(activityType: ActivityType) {
  localStorage.removeItem(activityType);
}

export function getFiltersFromLocalStorage(
  activityType: ActivityType
): FiltersType | null {
  const item = localStorage.getItem(activityType);
  if (!item) return null;
  return JSON.parse(item);
}
