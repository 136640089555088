import { getFiltersFromLocalStorage } from "data/activities/activities-session-storage";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";
import { TechniquesFilters } from "domain/entities/techniques.entities";
import { createAppAsyncThunk } from "domain/store.thunk";

export const getTechniques = createAppAsyncThunk(
  "techniques/getTechniques",
  async (
    filters: TechniquesFilters &
      Partial<ActivitiesPageFilters> &
      Partial<{ duration: DurationFilter; search: string }>,
    { extra: { techniquesGateway } }
  ) => {
    return await techniquesGateway.getTechniques(filters);
  }
);

export const getHomeTechniques = createAppAsyncThunk(
  "techniques/getHomeTechniques",
  async (filters: TechniquesFilters, { extra: { techniquesGateway } }) => {
    return await techniquesGateway.getHomeTechniques(filters);
  }
);

export const getTechnique = createAppAsyncThunk(
  "techniques/getTechnique",
  async (id: number, { extra: { techniquesGateway } }) => {
    return await techniquesGateway.getTechnique(id);
  }
);

export const getTechniqueAdvice = createAppAsyncThunk(
  "techniques/getTechniqueAdvice",
  async (id: number, { extra: { techniquesGateway } }) => {
    return await techniquesGateway.getTechniqueAdvice(id);
  }
);

export const getFavoritesTechniques = createAppAsyncThunk(
  "techniques/getFavoritesTechniques",
  async (techniqueIds: number[], { extra: { techniquesGateway } }) => {
    return await techniquesGateway.getFavoritesTechniques(techniqueIds);
  }
);

export const getPauses = createAppAsyncThunk(
  "techniques/getPauses",
  async (_, { extra: { techniquesGateway } }) => {
    return await techniquesGateway.getTechniques({
      types: ["pause"],
      "$sort[duration]": 1,
    });
  }
);

export const getSavedTechniqueFilters = createAppAsyncThunk(
  "techniques/getSavedTechniqueFilters",
  async (_, { extra: { techniquesGateway } }) => {
    return getFiltersFromLocalStorage("techniqueFilters");
  }
);
