import { RootState } from "domain/store";

export const selectHomeIntroTechniques = (state: RootState) => {
  return state.techniques.homeIntroTechniques;
};

export const selectHomeCoreTechniques = (state: RootState) => {
  return state.techniques.homeCoreTechniques;
};

export const selectHomeConclusionTechniques = (state: RootState) => {
  return state.techniques.homeConclusionTechniques;
};

export const selectTechniques = (state: RootState) => {
  return state.techniques.techniques;
};

export const selectIsTechniquesLoading = (state: RootState) => {
  return state.techniques.isTechniquesLoading;
};

export const selectTechnique = (state: RootState) => {
  return state.techniques.technique;
};

export const selectIsTechniqueLoading = (state: RootState) => {
  return state.techniques.isTechniqueLoading;
};

export const selectTechniqueAdvice = (state: RootState) => {
  return state.techniques.advice;
};

export const selectPauses = (state: RootState) => {
  return state.techniques.pauses;
};
