import { translate } from "ui/i18n";
import { Inputs } from "ui/pages/inputsType";
import { INTextarea } from "ui/pages/app/components/INTextarea";
import { BackButton } from "ui/pages/app/components/BackButton";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { parseDuration } from "ui/utils/formatters";
import { INDurationField } from "ui/pages/app/components/INDurationField";
import { INInput } from "ui/pages/app/components/INInput";
import { INRadioButtons } from "ui/pages/app/components/INRadioButtons";
import { HiUsers, HiVideoCamera } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { UseFormReturn } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { createSeance, updateSeance } from "domain/seances/seances.actions";
import { Dispatch, SetStateAction, useEffect } from "react";
import { selectIsSeanceLoading } from "domain/seances/seances.selectors";
import { Snackbar } from "ui/pages/snackbar";
import { ProgressionBar } from "ui/pages/app/components/ProgressionBar";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  handleBackButton: () => void;
  nextStep: () => void;
  formManager: UseFormReturn<Inputs, any, undefined>;
  setSeanceId: Dispatch<SetStateAction<number>>;
  seanceId: number | null;
  fetchSeance: (seanceId: number) => Promise<void>;
}

export function SeanceInfos({
  handleBackButton,
  nextStep,
  formManager,
  setSeanceId,
  seanceId,
  fetchSeance,
}: IProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSeanceLoading);
  const isDesktop = useWindowWidth();
  const { register, formState, handleSubmit, control, trigger } = formManager;
  const { errors, isValid, defaultValues } = formState;

  useEffect(() => {
    if (seanceId) trigger();
  }, []); //eslint-disable-line

  const participantsIsValid = (number: string) => {
    return (
      (!isNaN(Number(number)) && /^[1-9][0-9]?$/.test(number)) ||
      translate("ns1:Input.Errors.MustBeBetween1and99")
    );
  };

  const handleFormSubmit = async (data: Inputs, redirection?: boolean) => {
    const result = !!seanceId
      ? await dispatch(
          updateSeance({
            seance: {
              title: data.title,
              objectif: data.objectif,
              duration: parseDuration(data.duration),
              onsite: data.modality === "onsite",
              remote: data.modality === "remote",
              participants: Number(data.participants),
              participantsMin: Number(data.participants),
              participantsMax: Number(data.participants),
            },
            id: seanceId,
          })
        ).unwrap()
      : await dispatch(
          createSeance({
            title: data.title,
            objectif: data.objectif,
            duration: parseDuration(data.duration),
            onsite: data.modality === "onsite",
            remote: data.modality === "remote",
            participants: Number(data.participants),
            participantsMin: Number(data.participants),
            participantsMax: Number(data.participants),
          })
        ).unwrap();

    if (result.success && result.id) {
      setSeanceId(result.id);
      await fetchSeance(result.id);
      if (redirection) {
        Snackbar.showSuccess({
          title: translate("ns1:CreateSeance.DraftSaved"),
        });
        navigate("/my-creations");
      } else nextStep();
    }
  };

  return (
    <div>
      <div className="flex min-h-dvh w-full flex-col items-center px-2 py-4 md:px-12 md:py-9">
        <div className="flex h-10 w-full items-center justify-between px-2 md:px-0">
          <BackButton onClick={handleBackButton} displayText={isDesktop} />
        </div>

        <div className="mt-8 flex w-full max-w-[780px] items-center justify-center">
          <div className=" flex w-full flex-col items-center">
            <h4 className="mb-6 flex w-fit text-center font-bold text-secondaryGrey-900">
              1. {translate("ns1:CreateSeance.DefineSeance")}
            </h4>
            <ProgressionBar percentage={3} />

            <div className="mt-10 w-full rounded-[20px] bg-white px-4 py-6 md:px-20 md:py-12">
              <form
                onSubmit={handleSubmit((data) => handleFormSubmit(data, false))}
              >
                <INTextarea
                  type="textarea"
                  error={errors.objectif}
                  label={translate("ns1:Input.Labels.GeneralGoal") + "*"}
                  placeholder={translate("ns1:Input.Placeholders.WriteHere")}
                  className="mb-4 md:mb-6"
                  register={{
                    ...register("objectif", {
                      required: translate("ns1:Input.Errors.Required"),
                    }),
                  }}
                />

                <INDurationField
                  inputName="duration"
                  control={control}
                  label={translate("ns1:Input.Labels.ExpectedDuration") + "*"}
                  required
                  error={errors.duration}
                  defaultValue={defaultValues.duration}
                />
                <p className="text-medium mb-4 mt-2 text-sm text-darkGrey-700 md:mb-6">
                  {translate("ns1:CreateSeance.IndicativeDuration")}
                </p>

                <INInput
                  type="title"
                  error={errors.title}
                  label={translate("ns1:Input.Labels.SeanceName") + "*"}
                  placeholder={translate("ns1:Input.Placeholders.WriteHere")}
                  className="mb-4 md:mb-6"
                  register={{
                    ...register("title", {
                      required: translate("ns1:Input.Errors.Required"),
                    }),
                  }}
                />

                <INRadioButtons
                  inputName="modality"
                  options={modalityOptions}
                  label={translate("ns1:Input.Labels.Modality") + "*"}
                  defaultValue={defaultValues.modality}
                  className="mb-4 md:mb-6"
                  error={errors.modality}
                  control={control}
                  required
                />

                {/* <INRadioButtons
                  inputName="participants"
                  options={participantsOptions}
                  label={translate("ns1:Input.Labels.Participants") + "*"}
                  defaultValue={defaultValues.participants}
                  className="mb-0 md:mb-12"
                  error={errors.participants}
                  control={control}
                  required
                /> */}

                <INInput
                  label={translate("ns1:Input.Labels.Participants") + "*"}
                  placeholder="Ex: 10"
                  type="number"
                  min={4}
                  max={50}
                  register={{
                    ...register("participants", {
                      required: translate("ns1:Input.Errors.Required"),
                      validate: participantsIsValid,
                    }),
                  }}
                  className="mb-0 md:mb-12"
                  error={errors.participants}
                />

                {isDesktop && (
                  <>
                    {!isValid && (
                      <p className="text-medium my-8 text-sm text-darkGrey-700">
                        *{translate("ns1:Input.Errors.CompleteAllFields")}
                      </p>
                    )}

                    <PrimaryButton
                      type="submit"
                      height="52px"
                      width="100%"
                      disabled={!isValid || isLoading}
                    >
                      {translate("ns1:Button.Next")}
                    </PrimaryButton>

                    {isValid && (
                      <PrimaryButton
                        height="52px"
                        width="100%"
                        disabled={!isValid || isLoading}
                        outlined
                        onClick={handleSubmit((data) =>
                          handleFormSubmit(data, true)
                        )}
                        className="mt-4"
                      >
                        {translate("ns1:Button.SaveContinueLater")}
                      </PrimaryButton>
                    )}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      {!isDesktop && (
        <div className="sticky bottom-0 bg-white p-4 shadow-lg">
          {!isValid && (
            <p className="text-medium mb-4 text-sm text-darkGrey-700">
              *{translate("ns1:Input.Errors.CompleteAllFields")}
            </p>
          )}

          <PrimaryButton
            type="submit"
            height="52px"
            width="100%"
            disabled={!isValid || isLoading}
            onClick={handleSubmit((data) => handleFormSubmit(data, false))}
          >
            {translate("ns1:Button.Next")}
          </PrimaryButton>

          {isValid && (
            <PrimaryButton
              height="52px"
              width="100%"
              disabled={!isValid || isLoading}
              outlined
              onClick={handleSubmit((data) => handleFormSubmit(data, true))}
              className="mt-4"
            >
              {translate("ns1:Button.SaveContinueLater")}
            </PrimaryButton>
          )}
        </div>
      )}
    </div>
  );
}

const modalityOptions = [
  {
    name: translate("ns1:Activities.onsite"),
    value: "onsite",
    icon: HiUsers,
  },
  {
    name: translate("ns1:Activities.remote"),
    value: "remote",
    icon: HiVideoCamera,
  },
];

// const participantsOptions = [
//   {
//     name: translate("ns1:CreateSeance.UpTo10"),
//     value: "10",
//   },
//   {
//     name: translate("ns1:CreateSeance.UpTo15"),
//     value: "15",
//   },
//   {
//     name: translate("ns1:CreateSeance.UpTo20"),
//     value: "20",
//   },
//   {
//     name: translate("ns1:CreateSeance.MoreThan20"),
//     value: "0",
//   },
// ];
