import { selectUserId, selectUserName } from "domain/auth/auth.selectors";
import { Seance } from "domain/entities/seances.entities";
import {
  HiDotsVertical,
  HiHeart,
  HiOutlineDuplicate,
  HiOutlineHeart,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { CardBgGrey300 } from "ui/assets/svg/seance-card-backgrounds/CardBgGrey300";
import { CardBgLightGreen500 } from "ui/assets/svg/seance-card-backgrounds/CardBgLightGreen500";
import { CardBgPaleGreen200 } from "ui/assets/svg/seance-card-backgrounds/CardBgPaleGreen200";
import { CardBgPaleGreen400 } from "ui/assets/svg/seance-card-backgrounds/CardBgPaleGreen400";
import { CardBgPink200 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink200";
import { CardBgPink300 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink300";
import { CardBgPink400 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink400";
import { CardBgPink500 } from "ui/assets/svg/seance-card-backgrounds/CardBgPink500";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import {
  getDurationInHours,
  getTotalSeanceDuration,
} from "ui/utils/formatters";
import { MouseEvent } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { DateTime } from "luxon";
import {
  deleteSeance,
  duplicateSeance,
  getSeances,
} from "domain/seances/seances.actions";
import { Snackbar } from "ui/pages/snackbar";
import { ModalDeleteRessource } from "ui/pages/app/components/ModalDeleteRessource";
import { selectFavoriteSeances } from "domain/activities/activities.selectors";
import { toggleSeanceFavorite } from "ui/utils/toggle-activity-favorite";
import { useNavigate } from "react-router-dom";

interface IProps {
  seance: Seance;
  onClick: () => void;
}

export function MySeanceCard({ seance, onClick }: IProps) {
  const userName = useAppSelector(selectUserName);
  const userId = useAppSelector(selectUserId);
  const CardImage = getCardImage(getTotalSeanceDuration(seance));
  const popoverState = useDisclosure();
  const modalDeleteState = useDisclosure();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const favoriteSeance = useAppSelector(selectFavoriteSeances).find(
    (t) => t.seanceId === seance?.id
  );

  const handleCardClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const isNew = () => {
    return DateTime.fromISO(seance.createdAt).diffNow("days").days > -7;
  };

  const handleDeleteSeance = async (event: MouseEvent) => {
    event.stopPropagation();
    const result = await dispatch(deleteSeance(seance.id)).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Activities.RessourceDeleted"),
      });
      await dispatch(getSeances({ isInniz: false }));
    }
  };

  const handleOpenModalDelete = (event: MouseEvent) => {
    event.stopPropagation();
    modalDeleteState.onOpen();
  };

  const handleEditSeance = async (event: MouseEvent) => {
    event.stopPropagation();
    navigate("/create-seance", { state: { seanceId: seance.id } });
  };

  const handleDuplication = async (event: MouseEvent) => {
    event.stopPropagation();
    const result = await dispatch(duplicateSeance(seance.id)).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Seance.Duplicated"),
      });
      await dispatch(getSeances({ isInniz: false }));
    }
  };

  const handleFavoriteClick = async (e: MouseEvent) => {
    e.stopPropagation();
    toggleSeanceFavorite({
      favoriteActivity: favoriteSeance,
      dispatch,
      activityId: seance.id,
    });
  };

  return (
    <div
      className={`flex h-full cursor-pointer flex-col justify-between rounded-[20px] bg-white p-3 md:p-5 ${
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={handleCardClick}
    >
      <div>
        <div className="relative">
          <div className={!seance.active ? "opacity-30" : undefined}>
            <CardImage width="100%" height="100%" />
          </div>
          {!seance.active && !seance.title.includes("- Copie") && (
            <div className="absolute left-2 top-2 flex rounded-[36px] bg-secondaryGrey-800 px-2.5 py-0.5 text-xs font-semibold uppercase text-white md:left-4 md:top-4">
              {translate("ns1:MyCreations.Draft")}
            </div>
          )}
          {!seance.active && seance.title.includes("- Copie") && (
            <div className="absolute left-2 top-2 flex rounded-[36px] bg-secondaryGrey-800 px-2.5 py-0.5 text-xs font-semibold uppercase text-white md:left-4 md:top-4">
              {translate("ns1:MyCreations.Copy")}
            </div>
          )}

          {seance.active && isNew() && (
            <div className="absolute left-2 top-2 flex rounded-[36px] bg-secondaryGrey-800 px-2.5 py-0.5 text-xs font-semibold uppercase text-white md:left-4 md:top-4">
              {translate("ns1:MyCreations.New")}
            </div>
          )}

          <Popover
            isOpen={popoverState.isOpen}
            onOpen={popoverState.onOpen}
            onClose={popoverState.onClose}
            placement="right-start"
          >
            <PopoverTrigger>
              <button
                onClick={(e) => e.stopPropagation()}
                className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full bg-white md:right-4 md:top-4"
              >
                <HiDotsVertical className="text-secondaryGrey-900" size={18} />
              </button>
            </PopoverTrigger>
            <PopoverContent
              style={{
                isolation: "isolate",
                zIndex: 100,
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="flex flex-col  rounded-[20px] bg-white px-2 py-3 text-sm font-semibold text-darkGrey-700 shadow-lg">
                <button
                  className={`flex rounded-[10px] px-3 py-2 hover:bg-darkGrey-100 ${
                    !seance.active ? "opacity-30" : ""
                  }`}
                  disabled={!seance.active}
                  onClick={handleFavoriteClick}
                >
                  {!!favoriteSeance?.id ? (
                    <HiHeart
                      className="me-2 text-secondaryGrey-900"
                      size={20}
                    />
                  ) : (
                    <HiOutlineHeart
                      className="me-2 text-secondaryGrey-900"
                      size={20}
                    />
                  )}
                  {!!favoriteSeance?.id
                    ? translate("ns1:Button.AddedToFavorites")
                    : translate("ns1:Button.AddToFavorites")}
                </button>
                <button
                  className={`flex rounded-[10px] px-3 py-2 hover:bg-darkGrey-100`}
                  onClick={handleEditSeance}
                >
                  <HiOutlinePencil
                    className="me-2 text-secondaryGrey-900"
                    size={20}
                  />
                  {translate("ns1:Button.Edit")}
                </button>
                <button
                  className={`flex rounded-[10px] px-3 py-2 hover:bg-darkGrey-100 ${
                    !seance.active ? "opacity-30" : ""
                  }`}
                  disabled={!seance.active}
                  onClick={handleDuplication}
                >
                  <HiOutlineDuplicate
                    className="me-2 text-secondaryGrey-900"
                    size={20}
                  />
                  {translate("ns1:Button.Duplicate")}
                </button>
                <button
                  className="flex rounded-[10px] px-3 py-2 text-error hover:bg-darkGrey-100"
                  onClick={handleOpenModalDelete}
                >
                  <HiOutlineTrash className="me-2 text-error" size={20} />
                  {translate("ns1:Button.Delete")}
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </div>

        <span className="mt-2 block text-base font-bold">{seance.title}</span>
      </div>

      <div className="mt-3 flex items-center space-x-2 md:mt-8">
        {seance?.userId && seance.userId === userId && (
          <span className="flex h-7 w-7 items-center justify-center rounded-full bg-secondaryGrey-800 text-xs font-semibold uppercase text-secondaryGrey-500">
            {userName?.charAt(0)}
          </span>
        )}
        <span className="rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold">
          {getDurationInHours(getTotalSeanceDuration(seance))}
        </span>
      </div>
      <ModalDeleteRessource
        isOpen={modalDeleteState.isOpen}
        onClose={modalDeleteState.onClose}
        deleteRessource={handleDeleteSeance}
      />
    </div>
  );
}

function getCardImage(duration: number) {
  if (duration <= 70) return CardBgGrey300;
  else if (duration <= 90) return CardBgPaleGreen200;
  else if (duration <= 120) return CardBgLightGreen500;
  else if (duration <= 150) return CardBgPaleGreen400;
  else if (duration <= 180) return CardBgPink200;
  else if (duration <= 210) return CardBgPink300;
  else if (duration <= 360) return CardBgPink400;
  else return CardBgPink500;
}
