import { updateSeance } from "domain/seances/seances.actions";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { HiOutlineExclamation, HiPencil } from "react-icons/hi";
import { useAppDispatch } from "ui/hook/store";
import { translate } from "ui/i18n";
import { INDurationField } from "ui/pages/app/components/INDurationField";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { Inputs } from "ui/pages/inputsType";
import { Snackbar } from "ui/pages/snackbar";
import {
  formatDuration,
  getDurationInHours,
  parseDuration,
} from "ui/utils/formatters";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  seanceId: number;
  fetchSeance: () => Promise<void>;
  currentDuration: number;
  seanceDuration: string;
  isLoading: boolean;
  editDuration: boolean;
  setEditDuration: Dispatch<SetStateAction<boolean>>;
}

export function UpdateDuration({
  seanceId,
  fetchSeance,
  currentDuration,
  seanceDuration,
  isLoading,
  editDuration,
  setEditDuration,
}: IProps) {
  const dispatch = useAppDispatch();
  const isDesktop = useWindowWidth();

  const durationFormManager = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      duration: formatDuration(seanceDuration),
    },
  });

  const saveDuration = async (data: Inputs) => {
    const result = await dispatch(
      updateSeance({
        seance: {
          duration: parseDuration(data.duration),
        },
        id: seanceId,
      })
    ).unwrap();

    if (result.success && result.id) {
      fetchSeance();
      Snackbar.showSuccess({
        title: translate("ns1:CreateSeance.UpdatedDuration"),
      });
      setEditDuration(false);
    }
  };

  return (
    <div className="w-full rounded-2xl bg-white">
      <div className="p-6">
        <div className="mb-2 flex w-full justify-between">
          <p className="text-sm font-bold text-secondaryGrey-900 md:text-base">
            {translate("ns1:CreateSeance.Duration")}
          </p>
          {!editDuration && (
            <button onClick={() => setEditDuration(true)}>
              <HiPencil color="#485585" size={24} />
            </button>
          )}
        </div>
        {!editDuration ? (
          <p className="flex text-sm text-darkGrey-700 md:text-base">
            <span className="ms-1 font-bold">
              {getDurationInHours(currentDuration)}
            </span>{" "}
            / {getDurationInHours(Number(seanceDuration))}
            {currentDuration > Number(seanceDuration) && (
              <HiOutlineExclamation
                size={24}
                color="#485585"
                className="ms-2"
              />
            )}
          </p>
        ) : (
          <form onSubmit={durationFormManager.handleSubmit(saveDuration)}>
            <INDurationField
              inputName="duration"
              control={durationFormManager.control}
              required
              error={durationFormManager.formState.errors.duration}
              defaultValue={
                durationFormManager.formState.defaultValues.duration
              }
              className="mb-4 md:mb-6"
            />
            <div className="flex flex-col space-x-0 space-y-2 md:flex-row md:space-x-4 md:space-y-0">
              <PrimaryButton
                type="submit"
                height={isDesktop ? "46px" : "36px"}
                width="100%"
                disabled={!durationFormManager.formState.isValid || isLoading}
              >
                {translate("ns1:Button.Validate")}
              </PrimaryButton>

              <PrimaryButton
                height={isDesktop ? "46px" : "36px"}
                width="100%"
                disabled={isLoading}
                outlined
                onClick={() => setEditDuration(false)}
              >
                {translate("ns1:Button.Cancel")}
              </PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

//   <div className="flex w-full justify-between p-6">
//     <p className="text-sm font-bold  text-secondaryGrey-900 md:text-base">
//       {translate("ns1:CreateSeance.Duration")}
//     </p>
//     <p className="flex text-sm text-darkGrey-700 md:text-base">
//       <span className="ms-1 font-bold">{getDurationInHours(duration)}</span>{" "}
//       / {getDurationInHours(Number(seance.duration))}
//       {duration > Number(seance.duration) && (
//         <HiOutlineExclamation size={24} color="#485585" className="ms-2" />
//       )}
//     </p>
//   </div>;
