import {
  getFavoriteActivities,
  removeFavoriteActivity,
  setSeanceFavorite,
  setTechniqueFavorite,
} from "domain/activities/activities.actions";
import { FavoriteActivity } from "domain/entities/activities.entities";
import { AppDispatch } from "domain/store";

interface IProps {
  favoriteActivity: FavoriteActivity;
  dispatch: AppDispatch;
  activityId: number;
}

export async function toggleSeanceFavorite({
  favoriteActivity,
  dispatch,
  activityId,
}: IProps) {
  if (!!favoriteActivity?.id) {
    const result = await dispatch(
      removeFavoriteActivity(favoriteActivity?.id)
    ).unwrap();
    if (result.success) {
      dispatch(getFavoriteActivities());
    }
  } else dispatch(setSeanceFavorite(activityId));
}

export async function toggleTechniqueFavorite({
  favoriteActivity,
  dispatch,
  activityId,
}: IProps) {
  if (!!favoriteActivity?.id) {
    const result = await dispatch(
      removeFavoriteActivity(favoriteActivity?.id)
    ).unwrap();
    if (result.success) {
      dispatch(getFavoriteActivities());
    }
  } else dispatch(setTechniqueFavorite(activityId));
}
