import { selectIsAuthenticationLoading } from "domain/auth/auth.selectors";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { INInput } from "ui/pages/app/components/INInput";
import { Inputs } from "ui/pages/inputsType";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { sendNewPassword } from "domain/auth/auth.actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Snackbar } from "ui/pages/snackbar";

export function RecoverPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAuthenticationLoading);
  const { register, handleSubmit, formState, watch } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(
      sendNewPassword({ token, password: data.password })
    ).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:RecoverPassword.PasswordChanged"),
      });
      navigate("/login");
    }
  };

  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) navigate("/auth");
  }, [token]); //eslint-disable-line

  const validatePassword = (v: string) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).{8,}$/.test(v);
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">
        {translate("ns1:RecoverPassword.ChangeYourPassword")}
      </h2>

      <div className="w-full max-w-[430px]">
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="mb-4 mt-6 md:mb-8 md:mt-12"
        >
          <INInput
            type="password"
            error={errors.password}
            label={translate("ns1:Input.Labels.Password")}
            placeholder={translate("ns1:Input.Labels.Password")}
            register={{
              ...register("password", {
                required: translate("ns1:Input.Errors.Required"),
                validate: {
                  passwordFormat: (v) => validatePassword(v),
                },
              }),
            }}
          />
          <div
            className={`mt-2 text-xs leading-5 text-darkGrey-600 md:mt-4 ${
              errors.password ? "text-error" : ""
            }`}
          >
            <Trans
              i18nKey={
                "Input.Errors.PasswordFormat" as never[] | (string & never[])
              }
              components={[<span className="font-extrabold" />]}
            />
          </div>

          <INInput
            className="mb-4 mt-4 md:mb-8 md:mt-8"
            type="password"
            error={errors.passwordConfirmation}
            label={translate("ns1:Input.Labels.ConfirmPassword")}
            placeholder={translate("ns1:Input.Labels.ConfirmPassword")}
            register={{
              ...register("passwordConfirmation", {
                required: translate("ns1:Input.Errors.Required"),
                validate: (v) =>
                  watch("password") === v ||
                  translate("ns1:RecoverPassword.PasswordDontMatch"),
              }),
            }}
          />

          <PrimaryButton
            type="submit"
            disabled={!isValid || isLoading}
            width="100%"
            height="52px"
            onClick={handleSubmit(handleFormSubmit)}
          >
            {translate("ns1:Auth.Continue")}
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
}
