import {
  Opinion,
  Technique,
  TechniqueContext,
} from "domain/entities/techniques.entities";
import { z } from "zod";

export const sequenceDtoSchema = z.object({
  id: z.number().nullable().optional(),
  innizRef: z.string().nullable().optional(),
  airtableId: z.string().nullable().optional(),
  title: z.string().nullable().optional(),
  objectif: z.string().nullable().optional(),
  duration: z.string().nullable().optional(),
  type: z.string().nullable().optional(),
  participants: z.number().nullable().optional(),
  level: z.string().nullable().optional(),
  emotionalImpact: z.string().nullable().optional(),
  context: z.string().nullable().optional(),
  remote: z.boolean().nullable().optional(),
  onsite: z.boolean().nullable().optional(),
  active: z.boolean().nullable().optional(),
  validated: z.boolean().nullable().optional(),
  storyline: z.string().nullable().optional(),
  createdAt: z.string().nullable().optional(),
  updatedAt: z.string().nullable().optional(),
});

export const sequencesDtoSchema = z.array(sequenceDtoSchema);

const materielsDtoSchema = z.array(
  z.object({
    id: z.number().nullable(),
    innizRef: z.string().nullable(),
    airtableId: z.string().nullable(),
    title: z.string().nullable(),
    documentation: z.string().nullable(),
    tutorial: z.boolean().nullable(),
    isInniz: z.boolean().nullable(),
    createdAt: z.string().nullable(),
    updatedAt: z.string().nullable(),
  })
);

export const techniqueDtoSchema = z.object({
  id: z.number().nullable(),
  innizRef: z.string().nullable(),
  airtableId: z.string().nullable(),
  title: z.string().nullable(),
  objectif: z.string().nullable(),
  duration: z.string().nullable(),
  type: z.string().nullable(),
  participants: z.number().nullable(),
  level: z.string().nullable(),
  emotionalImpact: z.string().nullable(),
  context: z.string().nullable(),
  remote: z.boolean().nullable(),
  onsite: z.boolean().nullable(),
  active: z.boolean().nullable(),
  validated: z.boolean().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  sequences: sequencesDtoSchema.optional(),
  materiels: materielsDtoSchema.optional(),
  testimonials: z.array(z.custom<Opinion>()).optional(),
  premium: z.boolean().nullable(),
});

export const techniquesDtoSchema = z.array(techniqueDtoSchema);

export const seanceTechniqueDtoSchema = z.object({
  id: z.number().nullable(),
  seanceId: z.number().nullable(),
  techniqueId: z.number().nullable(),
  context: z.custom<TechniqueContext>().nullable(),
  order: z.number().nullable(),
  isInniz: z.boolean().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  technique: z.custom<Technique>().optional(),
});

export const seanceTechniquesDtoSchema = z.array(seanceTechniqueDtoSchema);
