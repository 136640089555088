import { createSlice } from "@reduxjs/toolkit";
import { CarouselSlide } from "domain/entities/carousel.entities";
import { getCarouselSlides } from "domain/carousel/carousel.actions";

export type CarouselState = {
  isCarouselLoading: boolean;
  slides: CarouselSlide[];
};

const initialState: CarouselState = {
  isCarouselLoading: false,
  slides: [],
};

export const carouselSlice = createSlice({
  name: "carousel",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //GET CAROUSEL SLIDES
    builder.addCase(getCarouselSlides.fulfilled, (state, action) => {
      state.slides = action.payload.slides;
      state.isCarouselLoading = false;
    });
    builder.addCase(getCarouselSlides.pending, (state, action) => {
      state.isCarouselLoading = true;
    });
  },
});
