import { HiOutlineMenu } from "react-icons/hi";
import { useWindowWidth } from "ui/utils/hooks";
import { INSearchField } from "ui/pages/app/components/INSearchField";
import { translate } from "ui/i18n";
import { useContext } from "react";
import { SidebarContext } from "ui/pages/layouts/components/SidebarContext";

interface IProps {
  search?: string;
  setSearch?: (value: string) => void;
  title: string;
  placeholder?: "TechniqueName";
}

export function PageHeader({ search, setSearch, title, placeholder }: IProps) {
  const isDesktop = useWindowWidth();
  const { setIsSidebarOpen } = useContext(SidebarContext);

  return (
    <div className="flex h-[52px] items-center justify-between">
      {!isDesktop && (
        <button onClick={() => setIsSidebarOpen(true)}>
          <HiOutlineMenu size={34} />
        </button>
      )}
      <h1 className="text-lg md:text-3xl">{title}</h1>
      <div style={{ opacity: typeof search === "string" && setSearch ? 1 : 0 }}>
        {isDesktop && (
          <INSearchField
            placeholder={translate(`ns1:Home.${placeholder || "Keywords"}`)}
            value={search}
            setValue={setSearch}
          />
        )}
        {!isDesktop && <div className="w-[34px]"></div>}
      </div>
    </div>
  );
}
