import {
  Box,
  HStack,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { Control, FieldError, useController } from "react-hook-form";
import { IconType } from "react-icons";
import { translate } from "ui/i18n";
import { Inputs } from "ui/pages/inputsType";

interface Props {
  options: { name: string; value: string; icon?: IconType }[];
  inputName: string;
  label: string;
  defaultValue: string;
  className?: string;
  error?: FieldError;
  control: Control<Inputs, any>;
  required?: boolean;
}

export function INRadioButtons({
  options,
  inputName,
  label,
  defaultValue,
  className,
  error,
  control,
  required,
}: Props) {
  const { field } = useController({
    name: inputName,
    control,
    rules: {
      required: required ? translate("ns1:Input.Errors.SelectAnOption") : false,
    },
    defaultValue,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({ ...field });

  const group = getRootProps();

  return (
    <div className={className}>
      <label className={`w-full`}>
        <span className="text-sm font-bold text-darkGrey-700">{label}</span>
      </label>
      <HStack {...group} className={`mt-2 flex flex-wrap `}>
        {options.map(({ name, value, icon }) => {
          const radio = getRadioProps({ value });
          return (
            <RadioButton key={value} {...radio} icon={icon}>
              {name}
            </RadioButton>
          );
        })}
      </HStack>
      {error && error.message && (
        <p className="mt-1 text-sm font-medium text-error">{error.message}</p>
      )}
    </div>
  );
}

function RadioButton(
  props: UseRadioProps & {
    children: React.ReactNode;
    icon?: IconType;
  }
) {
  const { getInputProps, getRadioProps, state } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <div
        className={`flex cursor-pointer items-center rounded-xl border border-darkGrey-800 px-4 py-2 text-sm font-bold ${
          state.isChecked ? "bg-darkGrey-800 text-white" : "bg-white"
        }`}
        {...checkbox}
      >
        {props.icon && (
          <props.icon
            size={24}
            color={state.isChecked ? "#fff" : "#47548C"}
            className="me-2"
          />
        )}
        {props.children}
      </div>
    </Box>
  );
}
