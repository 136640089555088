import {
  Technique,
  TechniqueContext,
  TechniqueType,
} from "domain/entities/techniques.entities";
import { HiMenuAlt4, HiTrash } from "react-icons/hi";
import { CardBgGrey400 as CardBgGrey400Square } from "ui/assets/svg/technique-card-bg-square/CardBgGrey400";
import { CardBgLightGreen400 as CardBgLightGreen400Square } from "ui/assets/svg/technique-card-bg-square/CardBgLightGreen400";
import { CardBgLightGreen500 as CardBgLightGreen500Square } from "ui/assets/svg/technique-card-bg-square/CardBgLightGreen500";
import { CardBgPaleGreen200 as CardBgPaleGreen200Square } from "ui/assets/svg/technique-card-bg-square/CardBgPaleGreen200";
import { CardBgPaleGreen400 as CardBgPaleGreen400Square } from "ui/assets/svg/technique-card-bg-square/CardBgPaleGreen400";
import { CardBgPink200 as CardBgPink200Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink200";
import { CardBgPink300 as CardBgPink300Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink300";
import { CardBgPink400 as CardBgPink400Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink400";
import { CardBgPink500 as CardBgPink500Square } from "ui/assets/svg/technique-card-bg-square/CardBgPink500";
import { getDurationInHours } from "ui/utils/formatters";
import { MouseEvent } from "react";
import { useAppSelector } from "ui/hook/store";
import { selectUserName } from "domain/auth/auth.selectors";
import { ModalTechnique } from "ui/pages/app/seances/create-seance/steps/add-content/modals/ModalTechnique";
import { useDisclosure } from "@chakra-ui/hooks";
import { ModalCreatePersonalStep } from "ui/pages/app/seances/create-seance/steps/add-content/modals/ModalCreatePersonalStep";

interface IProps {
  technique: Technique;
  onDelete?: () => void;
  fetchSeance: (id?: number) => Promise<void>;
}

export function InlineTechniqueCard({
  technique,
  onDelete,
  fetchSeance,
}: IProps) {
  const CardImage = getImage(technique?.type as TechniqueType);
  const userName = useAppSelector(selectUserName);
  const modalTechnique = useDisclosure();
  const modalCreatePersonalStep = useDisclosure();

  const handleDeleteClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onDelete) onDelete();
  };

  const handleCardClick = () => {
    if (technique?.type === "CUSTOM") {
      modalCreatePersonalStep.onOpen();
    } else {
      modalTechnique.onOpen();
    }
  };

  return (
    <div
      className={`flex w-full flex-row items-center justify-between rounded-[16px] bg-white p-3`}
      onClick={handleCardClick}
    >
      <div className="flex flex-row items-center">
        <div className="me-2 h-9 w-9">
          <button
            className="flex h-9 w-9 items-center justify-center"
            onClick={handleDeleteClick}
          >
            <HiTrash size={20} color="#485585" />
          </button>
        </div>
        {technique?.type !== "CUSTOM" && technique?.type !== "pause" && (
          <div style={{ width: "64px", height: "64px" }} className="me-4">
            <CardImage width="64px" height="64px" />
          </div>
        )}
        <div
          className={`flex flex-col space-y-2 ${
            technique?.type === "CUSTOM" ? "ms-2" : ""
          }`}
        >
          <p className="text-sm font-bold text-secondaryGrey-900">
            {technique?.title}
          </p>
          <div className="flex space-x-2">
            {technique?.type === "CUSTOM" && (
              <span className="flex h-7 w-7 items-center justify-center rounded-full bg-secondaryGrey-800 text-xs font-semibold uppercase text-secondaryGrey-500">
                {userName?.charAt(0)}
              </span>
            )}
            {technique?.duration && Number(technique?.duration) !== 0 && (
              <div className="flex w-fit items-center rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold">
                {getDurationInHours(Number(technique.duration))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-9 w-9">
        <button className="flex h-9 w-9 items-center justify-center">
          <HiMenuAlt4 size={20} color="#485585" />
        </button>
      </div>

      <ModalTechnique
        partialTechnique={technique}
        isOpen={modalTechnique.isOpen}
        closeViewTechniqueModal={modalTechnique.onClose}
      />

      <ModalCreatePersonalStep
        isOpen={modalCreatePersonalStep.isOpen}
        onClose={modalCreatePersonalStep.onClose}
        personalStep={technique}
        fetchSeance={fetchSeance}
        context={technique?.context as TechniqueContext}
      />
    </div>
  );
}

function getImage(activityType: TechniqueType) {
  switch (activityType) {
    case "ice_breaker":
      return CardBgLightGreen500Square;
    case "problematique":
      return CardBgPink500Square;
    case "equipes":
      return CardBgPaleGreen400Square;
    case "brainstorming":
      return CardBgPink300Square;
    case "projects":
      return CardBgPaleGreen200Square;
    case "pratiques":
      return CardBgPink200Square;
    case "evaluation":
      return CardBgPink400Square;
    case "pause":
      return CardBgLightGreen400Square;
    case "vote":
      return CardBgGrey400Square;
    default:
      return CardBgLightGreen500Square;
  }
}
