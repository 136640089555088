import { RadioGroup } from "@chakra-ui/react";
import { INCheckbox } from "ui/pages/app/components/INCheckbox";
import {
  contextOfUseFilters,
  durationFilters,
  modalitiesFilters,
  objectiveFilters,
  typesFilters,
} from "ui/pages/app/components/activities/activitiesFiltersValues";
import { INRangeSlider } from "../INRangeSlider";
import {
  DurationFilter,
  ActivitiesPageFilters,
} from "domain/entities/activities.entities";
import { INRadioButton } from "../INRadioButton";
import { HiArrowLeft } from "react-icons/hi";
import { translate } from "ui/i18n";
import { INInput } from "ui/pages/app/components/INInput";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";

interface IProps {
  filters: ActivitiesPageFilters;
  setFilters: (
    filters: ActivitiesPageFilters & {
      [key: string]: string | string[] | boolean | number | DurationFilter;
    }
  ) => void;
  fetchActivities?: () => void;
  activitiesType: "techniques" | "seances";
  maxDuration?: number;
  minDuration?: number;
  setIsFiltersOpen: (isFiltersOpen: boolean) => void;
  resetFilters: () => void;
}

export function MobileActivitiesFilters({
  filters,
  setFilters,
  fetchActivities,
  activitiesType,
  maxDuration,
  minDuration,
  setIsFiltersOpen,
  resetFilters,
}: IProps) {
  return (
    <div className="w-screen">
      <div className="p-4">
        <button
          onClick={() => setIsFiltersOpen(false)}
          className="my-2.5 flex h-5 items-center"
        >
          <HiArrowLeft size={24} className="text-darkGrey-800" />
        </button>

        <div className="mt-8 flex flex-col space-y-8 rounded-[20px] bg-white p-6">
          {/* ContextOfUse filter */}
          {activitiesType !== "seances" && (
            <div>
              <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
                {translate("ns1:Activities.Buttons.ContextOfUse")}
              </div>
              <div className="flex flex-col space-y-4">
                <>
                  {contextOfUseFilters.map((filter) => (
                    <INCheckbox
                      key={filter.value}
                      inputName={filter.value}
                      text={filter.text}
                      value={filters.contexts?.includes(filter.value)}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          contexts: filters.contexts?.includes(filter.value)
                            ? filters.contexts.filter((c) => c !== filter.value)
                            : [...filters.contexts, filter.value],
                        })
                      }
                      bold
                    />
                  ))}
                </>
              </div>
            </div>
          )}

          {/* Type filter */}
          {activitiesType !== "seances" && (
            <div>
              <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
                {translate("ns1:Activities.Buttons.Type")}
              </div>
              <div className="flex flex-col space-y-4">
                <>
                  {typesFilters.map((filter) => (
                    <INCheckbox
                      key={filter.value}
                      inputName={filter.value}
                      text={filter.text}
                      value={filters.types?.includes(filter.value)}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          types: filters.types?.includes(filter.value)
                            ? filters.types.filter((c) => c !== filter.value)
                            : [...filters.types, filter.value],
                        })
                      }
                      bold
                    />
                  ))}
                </>
              </div>
            </div>
          )}

          {/* Objective filter */}
          {activitiesType !== "seances" && (
            <div>
              <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
                {translate("ns1:Activities.Buttons.Objective")}
              </div>
              <div className="flex flex-col space-y-4">
                <>
                  {objectiveFilters.map((filter) => (
                    <INCheckbox
                      key={filter.value}
                      inputName={filter.value}
                      text={filter.text}
                      value={filters.objectives?.includes(filter.value)}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          objectives: filters.objectives?.includes(filter.value)
                            ? filters.objectives.filter(
                                (c) => c !== filter.value
                              )
                            : [...filters.objectives, filter.value],
                        })
                      }
                      bold
                    />
                  ))}
                </>
              </div>
            </div>
          )}

          {/* Duration filter */}
          <div>
            <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
              {translate("ns1:Activities.Buttons.Duration")}
            </div>
            {activitiesType !== "seances" ? (
              <INRangeSlider
                min={minDuration}
                max={maxDuration}
                duration={filters.duration}
                setDuration={(duration) =>
                  setFilters({
                    ...filters,
                    duration,
                  })
                }
                width="100%"
                step={5}
                fetchActivities={fetchActivities}
              />
            ) : (
              <RadioGroup
                onChange={(value) =>
                  setFilters({ ...filters, selectedDuration: value })
                }
                value={filters.selectedDuration}
                className="flex flex-col space-y-4"
              >
                {durationFilters.map((filter) => (
                  <INRadioButton
                    key={filter.value}
                    text={filter.text}
                    value={filter.value}
                    selectedValue={filters.selectedDuration}
                  />
                ))}
              </RadioGroup>
            )}
          </div>

          {/* Participants filter */}
          <div>
            <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
              {translate("ns1:Activities.Buttons.Participants")}
            </div>
            <INInput
              value={filters.participants}
              onChange={(value) =>
                setFilters({ ...filters, participants: value })
              }
              placeholder="Ex: 10"
              type="number"
              min={4}
              max={50}
              id="participants-input"
              className="w-full max-w-[100px]"
            />
          </div>

          {/* Modality filter */}
          <div>
            <div className="mb-4 text-lg font-bold text-secondaryGrey-900">
              {translate("ns1:Activities.Buttons.Modality")}
            </div>
            <div className="flex flex-col space-y-4">
              {modalitiesFilters.map((filter) => (
                <INCheckbox
                  key={filter.value}
                  inputName={filter.value}
                  text={filter.text}
                  value={filters.modalities?.includes(filter.value)}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      modalities: filters.modalities?.includes(filter.value)
                        ? filters.modalities.filter((c) => c !== filter.value)
                        : [...filters.modalities, filter.value],
                    })
                  }
                  bold
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 z-[100] flex w-full flex-col items-center bg-white px-6 py-4 shadow-lg">
        <div className="w-full max-w-[590px]">
          <PrimaryButton
            height="46px"
            width="100%"
            outlined
            onClick={resetFilters}
          >
            {translate("ns1:Button.ResetFilters")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
