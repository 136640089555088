import { selectUserInfos } from "domain/auth/auth.selectors";
import React, { useEffect } from "react";
import { useAppSelector } from "ui/hook/store";

export function StripePricingTable() {
  const user = useAppSelector(selectUserInfos);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": process.env.REACT_APP_STRIPE_TABLE_ID,
    "publishable-key": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    "customer-email": user?.email,
  });
}

export default StripePricingTable;
