import { translate } from "ui/i18n";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { INInput } from "ui/pages/app/components/INInput";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Inputs } from "ui/pages/inputsType";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { registerUser } from "domain/auth/auth.actions";
import { selectIsAuthenticationLoading } from "domain/auth/auth.selectors";

export function RegistrationStep1({
  email,
  setStep,
}: {
  email: string;
  setStep: Dispatch<SetStateAction<number>>;
}) {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsAuthenticationLoading);
  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      password: "",
    },
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(
      registerUser({ email, password: data.password })
    ).unwrap();
    if (result.success) {
      setStep(2);
    }
  };

  const validatePassword = (v: string) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).{8,}$/.test(v);
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">
        {translate("ns1:Register.CreateYourPassword")}
      </h2>

      <div className="w-full max-w-[430px]">
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="mb-4 mt-6 md:mb-8 md:mt-12"
        >
          <INInput
            type="password"
            error={errors.password}
            label={translate("ns1:Input.Labels.Password")}
            placeholder={translate("ns1:Input.Labels.Password")}
            register={{
              ...register("password", {
                required: translate("ns1:Input.Errors.Required"),
                validate: {
                  passwordFormat: (v) => validatePassword(v),
                },
              }),
            }}
          />
          <div
            className={`mb-4 mt-4 text-xs leading-5 text-darkGrey-600 md:mb-8 md:mt-6 ${
              errors.password ? "text-error" : ""
            }`}
          >
            <Trans
              i18nKey={
                "Input.Errors.PasswordFormat" as never[] | (string & never[])
              }
              components={[<span className="font-extrabold" />]}
            />
          </div>

          <PrimaryButton
            type="submit"
            disabled={!isValid || isLoading}
            width="100%"
            height="52px"
            onClick={handleSubmit(handleFormSubmit)}
          >
            {translate("ns1:Auth.Continue")}
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
}
