import {
  ActivitiesPageFilters,
  DurationFilter,
} from "domain/entities/activities.entities";
import {
  SeanceTechnique,
  Technique,
  TechniqueContext,
} from "domain/entities/techniques.entities";
import { selectIsSeanceLoading } from "domain/seances/seances.selectors";
import { getTechniques } from "domain/techniques/techniques.actions";
import { selectTechniques } from "domain/techniques/techniques.selectors";
import { useEffect, useState } from "react";
import { HiAdjustments } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { DesktopActivitiesFilters } from "ui/pages/app/components/activities/DesktopActivitiesFilters";
import { MobileActivitiesFilters } from "ui/pages/app/components/activities/MobileActivitiesFilters";
import { TechniqueCard } from "ui/pages/app/components/activities/TechniqueCard";
import { BackButton } from "ui/pages/app/components/BackButton";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import { useWindowWidth } from "ui/utils/hooks";
import { SelectionZone } from "ui/pages/app/seances/create-seance/components/SelectionZone";
import { Modal, ModalContent } from "@chakra-ui/modal";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { ModalTechnique } from "ui/pages/app/seances/create-seance/steps/add-content/modals/ModalTechnique";
import { useDisclosure } from "@chakra-ui/hooks";
import { INSearchField } from "ui/pages/app/components/INSearchField";
import { getFiltersCount } from "ui/utils/get-filters-count";
import { v4 as uuid } from "uuid";

interface IProps {
  selectedDuration: number;
  isOpen: boolean;
  onClose: () => void;
  context: TechniqueContext;
  participants: number;
  modality: string;
  currentDuration: number;
  onOpen: () => void;
  addTechniques: (
    techniques: SeanceTechnique[],
    context: TechniqueContext,
    order: number
  ) => Promise<{
    success: boolean;
  }>;
}

export function ModalChooseTechniques({
  selectedDuration,
  isOpen,
  onClose,
  context,
  participants,
  modality,
  currentDuration,
  onOpen,
  addTechniques,
}: IProps) {
  const isLoading = useAppSelector(selectIsSeanceLoading);
  const dispatch = useAppDispatch();
  const isDesktop = useWindowWidth();
  const techniques = useAppSelector(selectTechniques);
  const modalViewTechnique = useDisclosure();
  const minDuration = 0;
  const maxDuration = 145;
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isSelectionZoneOpen, setIsSelectionZoneOpen] = useState(false);
  const [selectedTechniques, setSelectedTechniques] = useState<Technique[]>([]);
  const [techniqueToView, setTechniqueToView] = useState<Technique | null>();
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState<
    ActivitiesPageFilters & {
      [key: string]: string[] | string | boolean | number | DurationFilter;
    }
  >({
    contexts: [context],
    types: [],
    objectives: [],
    participants: "",
    modalities: [],
    duration: {
      $gte: minDuration,
      $lte: maxDuration,
    },
  });

  useEffect(() => {
    setFilters({
      ...filters,
      contexts: [context],
      modalities: [modality],
      participants: "",
    });
  }, [context, participants, modality]); //eslint-disable-line

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchTechniques();
    }, 500);
    return () => clearTimeout(timeout);
  }, [filters, context, search]); //eslint-disable-line

  const fetchTechniques = () => {
    dispatch(
      getTechniques({
        pauses: false,
        search,
        isInniz: true,
        ...filters,
      })
    );
  };

  const updateSelectedTechniques = (
    technique: Technique,
    action: "add" | "remove"
  ) => {
    if (action === "remove") {
      if (technique.tempId) {
        setSelectedTechniques([
          ...selectedTechniques.filter((t) => t.tempId !== technique.tempId),
        ]);
      } else {
        setSelectedTechniques([
          ...selectedTechniques.filter((t) => t.id !== technique.id),
        ]);
      }
    } else if (action === "add") {
      setSelectedTechniques([
        ...selectedTechniques,
        { ...technique, tempId: uuid() },
      ]);
    }
  };

  const addTechniquesToSeance = async () => {
    const result = await addTechniques(
      selectedTechniques.map((technique) => {
        return {
          technique,
        };
      }),
      context,
      selectedTechniques.length
    );
    if (result.success) {
      setSelectedTechniques([]);
      onClose();
    }
  };

  const closeViewTechniqueModal = () => {
    modalViewTechnique.onClose();
    onOpen();
  };

  const openViewTechniqueModal = (technique: Technique) => {
    setTechniqueToView(technique);
    onClose();
    modalViewTechnique.onOpen();
  };

  const resetFilters = () => {
    setFilters({
      contexts: [],
      types: [],
      objectives: [],
      participants: "",
      modalities: [],
      duration: {
        $gte: minDuration,
        $lte: maxDuration,
      },
    });
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalContent>
          <div className="relative h-dvh w-full overflow-x-hidden bg-darkGrey-100">
            <div
              className={`flex min-h-dvh flex-row overflow-x-hidden ${
                isFiltersOpen ? "filters-open" : "filters-closed"
              }`}
              style={{ width: isDesktop ? "100%" : "200vw" }}
            >
              <div className="w-full px-2 py-4 md:px-12 md:py-9">
                <div className="flex h-10 w-full items-center justify-between px-2 md:px-0">
                  <BackButton onClick={onClose} displayText={isDesktop} />
                </div>

                <div className="mt-8 flex w-full justify-center">
                  <h4 className="w-fit text-center font-bold text-secondaryGrey-900">
                    {translate(`ns1:CreateSeance.SelectTechniques.${context}`)}
                  </h4>
                </div>

                {isDesktop ? (
                  <>
                    <INSearchField
                      placeholder={translate("ns1:Home.TechniqueName")}
                      value={search}
                      setValue={setSearch}
                      className="mt-8"
                      width="100%"
                    />
                    <DesktopActivitiesFilters
                      filters={filters}
                      setFilters={setFilters}
                      fetchActivities={fetchTechniques}
                      activitiesType="techniques"
                      minDuration={minDuration}
                      maxDuration={maxDuration}
                      className="!mt-4"
                      resetFilters={resetFilters}
                    />
                  </>
                ) : (
                  <div className="mt-7 flex">
                    <INSearchField
                      placeholder={translate("ns1:Home.TechniqueName")}
                      value={search}
                      setValue={setSearch}
                      width="100%"
                      height="48px"
                    />
                    <div className="ms-3 h-12 w-12">
                      <button
                        className="relative flex h-12  w-12 items-center justify-center rounded-full border border-darkGrey-200 bg-white py-2 text-base font-bold"
                        onClick={() => setIsFiltersOpen(true)}
                      >
                        <HiAdjustments color="#2B3674" size="24" />
                        <span
                          className="absolute flex h-4 w-4 items-center justify-center rounded-full bg-pink-400 text-[9px] text-white"
                          style={{ top: "-2px", right: "-2px" }}
                        >
                          {getFiltersCount(filters, minDuration, maxDuration)}
                        </span>
                      </button>
                    </div>
                  </div>
                )}

                <div
                  className={`mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4 `}
                >
                  {!isLoading ? (
                    <>
                      {techniques.map((technique, index) => (
                        <TechniqueCard
                          technique={technique}
                          key={"technique" + index}
                          onClick={() => openViewTechniqueModal(technique)}
                          selection
                          onSelect={() =>
                            updateSelectedTechniques(technique, "add")
                          }
                          selected={
                            selectedTechniques.filter(
                              (t) => technique?.id === t?.id
                            ).length > 0
                          }
                          favButton={false}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {[...Array(12)].map((_, index) => (
                        <INSkeleton key={"techniques-skeleton" + index} />
                      ))}
                    </>
                  )}
                </div>
              </div>

              {!isDesktop && (
                <div className="mb-[64px]">
                  <MobileActivitiesFilters
                    filters={filters}
                    setFilters={setFilters}
                    fetchActivities={fetchTechniques}
                    activitiesType="techniques"
                    minDuration={minDuration}
                    maxDuration={maxDuration}
                    setIsFiltersOpen={setIsFiltersOpen}
                    resetFilters={resetFilters}
                  />
                </div>
              )}
            </div>

            {!isFiltersOpen && (
              <>
                <div className="sticky bottom-[78px] z-[75] max-h-dvh w-full">
                  <SelectionZone
                    selectedTechniques={selectedTechniques}
                    duration={selectedDuration}
                    updateSelectedTechniques={updateSelectedTechniques}
                    currentDuration={currentDuration}
                    isOpen={isSelectionZoneOpen}
                    setIsOpen={setIsSelectionZoneOpen}
                  />
                </div>

                <div className="sticky bottom-0 z-[100] flex w-dvw flex-col items-center bg-white px-6 py-4 shadow-lg">
                  <div className="w-full max-w-[590px]">
                    <PrimaryButton
                      type="submit"
                      height="46px"
                      width="100%"
                      onClick={addTechniquesToSeance}
                      disabled={selectedTechniques.length === 0}
                    >
                      {translate("ns1:CreateSeance.AddToSeance")}
                    </PrimaryButton>
                  </div>
                </div>
              </>
            )}
          </div>
        </ModalContent>
      </Modal>

      <ModalTechnique
        isOpen={modalViewTechnique.isOpen}
        partialTechnique={techniqueToView}
        closeViewTechniqueModal={closeViewTechniqueModal}
        selected={selectedTechniques.some((t) => t?.id === techniqueToView?.id)}
        selectedTechniques={selectedTechniques}
        setSelectedTechniques={setSelectedTechniques}
        updateSelectedTechniques={updateSelectedTechniques}
        setIsSelectionZoneOpen={setIsSelectionZoneOpen}
      />
    </div>
  );
}
