import { HttpService } from "data/http-service";
import { CarouselGateway } from "domain/carousel/carousel.gateway";
import { CarouselSlide } from "domain/entities/carousel.entities";
import { carouselSlidesDtoSchema } from "data/carousel/carousel.dto.schema";
import { AxiosError } from "axios";

export class CarouselHttpGateway implements CarouselGateway {
  async getCarouselSlides(): Promise<{
    slides: CarouselSlide[];
    success: boolean;
  }> {
    const url = "carroussel";
    return HttpService.get(url)
      .then((response) => {
        if (response.status === 200) {
          const parsedSlides = carouselSlidesDtoSchema.safeParse(
            response.data.data
          );
          if (parsedSlides.success) {
            return {
              slides: parsedSlides.data,
              success: true,
            };
          } else return { slides: [], success: false };
        } else {
          // Snackbar.showError({
          //   title: translate("ns1:Carousel.FindError"),
          // });
          return {
            slides: [],
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        // Snackbar.showError({
        //   title: translate("ns1:Carousel.FindError"),
        // });
        return { slides: [], success: false };
      });
  }
}
