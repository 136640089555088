import {
  ActivityMateriel,
  FavoriteActivity,
} from "domain/entities/activities.entities";
import { z } from "zod";

export const adviceDtoSchema = z.object({
  id: z.number(),
  title: z.string(),
  content: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const materielsDtoSchema = z.array(z.custom<ActivityMateriel>());

export const favoriteActivityDtoSchema = z.custom<FavoriteActivity>();

export const favoriteActivitiesDtoSchema = z.array(
  z.custom<FavoriteActivity>()
);
