import { RootState } from "domain/store";

export const selectIsAuthenticated = (state: RootState) => {
  return state.auth.isAuthenticated;
};

export const selectIsAuthenticationLoading = (state: RootState) => {
  return state.auth.isAuthenticationLoading;
};

export const selectUserName = (state: RootState) => {
  return state.auth.userInfos.firstName;
};

export const selectIsPremium = (state: RootState) => {
  return state.auth.userInfos.isPremium;
};

export const selectIsCustomer = (state: RootState) => {
  return state.auth.userInfos.isCustomer;
};

export const selectUserId = (state: RootState) => {
  return state.auth.userInfos.id;
};

export const selectUserInfos = (state: RootState) => {
  return state.auth.userInfos;
};

export const selectPortalSessionUrl = (state: RootState) => {
  return state.auth.portalSessionUrl;
};
