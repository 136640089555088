import {
  getSavedSeanceFilters,
  getSeances,
} from "domain/seances/seances.actions";
import {
  selectIsSeancesLoading,
  selectSeances,
} from "domain/seances/seances.selectors";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { useWindowWidth } from "ui/utils/hooks";
import { PageHeader } from "ui/pages/app/components/PageHeader";
import { translate } from "ui/i18n";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import { SeanceCard } from "ui/pages/app/components/activities/SeanceCard";
import {
  ActivitiesPageFilters,
  DurationFilter,
} from "domain/entities/activities.entities";
import { DesktopActivitiesFilters } from "ui/pages/app/components/activities/DesktopActivitiesFilters";
import { HiAdjustments } from "react-icons/hi";
import { MobileActivitiesFilters } from "ui/pages/app/components/activities/MobileActivitiesFilters";
import { useNavigate } from "react-router-dom";
import { selectIsPremium } from "domain/auth/auth.selectors";
import { Seance } from "domain/entities/seances.entities";
import { Snackbar } from "ui/pages/snackbar";
import { getFiltersCount } from "ui/utils/get-filters-count";
import { setFiltersInLocalStorage } from "data/activities/activities-session-storage";

export function Seances() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsSeancesLoading);
  const isPremium = useAppSelector(selectIsPremium);
  const seances = useAppSelector(selectSeances);
  const isDesktop = useWindowWidth();
  const navigate = useNavigate();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<
    ActivitiesPageFilters & {
      [key: string]: string[] | string | boolean | number | DurationFilter;
    }
  >({
    participants: "",
    modalities: [],
    selectedDuration: "",
  });

  const fetchSeances = () => {
    setFiltersInLocalStorage("seanceFilters", filters);
    dispatch(
      getSeances({
        isInniz: true,
        ...filters,
      })
    );
  };

  const getSavedFilters = async () => {
    const savedFilters = await dispatch(getSavedSeanceFilters()).unwrap();
    if (savedFilters) {
      setFilters(savedFilters);
    }
  };

  useEffect(() => {
    getSavedFilters();
  }, []); //eslint-disable-line

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchSeances();
    }, 500);
    return () => clearTimeout(timeout);
  }, [filters]); //eslint-disable-line

  const handleCardClick = async (seance: Seance) => {
    if (!isPremium && !!seance.premium) {
      await Snackbar.removeSubscriptionAlert();
      Snackbar.showSubscriptionAlert(() => navigate("/account"), "seance");
    } else navigate(`/seance/${seance?.id}`);
  };

  const resetFilters = () => {
    setFilters({
      participants: "",
      modalities: [],
      selectedDuration: "",
    });
  };

  return (
    <div
      className={`flex flex-row overflow-x-hidden ${
        isFiltersOpen ? "filters-open" : "filters-closed"
      }`}
      style={{ width: isDesktop ? "100%" : "200vw" }}
    >
      <div className="w-screen px-2 pt-2 md:px-12 md:pt-8">
        <PageHeader title={translate("ns1:Routes.Seances")} />

        {isDesktop ? (
          <DesktopActivitiesFilters
            filters={filters}
            setFilters={setFilters}
            fetchActivities={fetchSeances}
            activitiesType="seances"
            resetFilters={resetFilters}
          />
        ) : (
          <button
            className="mt-6 flex w-full items-center justify-center rounded-xl border border-darkGrey-200 bg-white py-2 text-base font-bold"
            onClick={() => setIsFiltersOpen(true)}
          >
            <HiAdjustments color="#2B3674" size="20" className="me-1.5" />
            {translate("ns1:Button.Filters")}
            <span className="ms-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-pink-400 text-xs text-white">
              {getFiltersCount(filters)}
            </span>
          </button>
        )}

        <div className="mb-8 mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {!isLoading ? (
            <>
              {seances?.map((seance, index) => (
                <SeanceCard
                  seance={seance}
                  key={"seance" + index}
                  onClick={() => handleCardClick(seance)}
                />
              ))}
            </>
          ) : (
            <>
              {[...Array(12)].map((_, index) => (
                <INSkeleton key={"seances-skeleton" + index} />
              ))}
            </>
          )}
        </div>
      </div>

      {!isDesktop && (
        <MobileActivitiesFilters
          filters={filters}
          setFilters={setFilters}
          fetchActivities={fetchSeances}
          activitiesType="seances"
          setIsFiltersOpen={setIsFiltersOpen}
          resetFilters={resetFilters}
        />
      )}
    </div>
  );
}
