import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { translate } from "ui/i18n";
import { BackButton } from "ui/pages/app/components/BackButton";
import { Inputs } from "ui/pages/inputsType";
import { useWindowWidth } from "ui/utils/hooks";
import { INInput } from "ui/pages/app/components/INInput";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import {
  selectIsAuthenticationLoading,
  selectUserInfos,
} from "domain/auth/auth.selectors";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { updatePersonalInfos } from "domain/auth/auth.actions";
import { Snackbar } from "ui/pages/snackbar";

export function ChangeEmail() {
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();
  const isLoading = useAppSelector(selectIsAuthenticationLoading);
  const user = useAppSelector(selectUserInfos);
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });
  const { errors, isValid } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(
      updatePersonalInfos({
        data: {
          email: data.email,
        },
        userId: user?.id,
      })
    ).unwrap();

    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Account.UpdatedEmail"),
      });
    }
  };

  return (
    <div className="flex min-h-dvh flex-col bg-darkGrey-100 p-4 md:p-8">
      <BackButton
        onClick={() => navigate("/account")}
        displayText={isDesktop}
      />
      <div className="mt-5 flex w-full max-w-[700px] flex-col items-center justify-center self-center rounded-[20px] bg-white px-6 py-10 md:p-20">
        <h2 className="text-center">{translate("ns1:Account.ChangeEmail")}</h2>

        <div className="w-full">
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="mt-6 md:mt-12"
          >
            <INInput
              error={errors.email}
              label={translate("ns1:Input.Labels.NewEmail")}
              placeholder="mail@exemple.com"
              register={{
                ...register("email", {
                  required: translate("ns1:Input.Errors.Required"),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: translate("ns1:Input.Errors.EmailFormat"),
                  },
                }),
              }}
              className="mb-2 md:mb-4"
            />

            <PrimaryButton
              className="mt-6 md:mt-20"
              type="submit"
              disabled={!isValid || isLoading}
              width="100%"
              height="52px"
              onClick={handleSubmit(handleFormSubmit)}
            >
              {translate("ns1:Button.Validate")}
            </PrimaryButton>
          </form>
        </div>
      </div>
    </div>
  );
}
