import { useNavigate } from "react-router-dom";
import { BackButton } from "../components/BackButton";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { selectUserInfos } from "domain/auth/auth.selectors";
import { jobOptions } from "ui/pages/auth/register/TellUsMore";
import { AccountStats } from "ui/assets/svg/AccountStats";
import { useWindowWidth } from "ui/utils/hooks";
import { translate } from "ui/i18n";
import { HorizontalDivider } from "ui/pages/app/components/HorizontalDivider";
import { SubcriptionManagement } from "ui/pages/app/account/components/SubcriptionManagement";
import { SubscriptionInfos } from "ui/pages/app/account/components/SubscriptionInfos";
import { AboutYou } from "ui/pages/app/account/components/AboutYou";
import { getUserInfos } from "domain/auth/auth.actions";
import { useEffect } from "react";
export function Account() {
  const navigate = useNavigate();
  const user = useAppSelector(selectUserInfos);
  const isDesktop = useWindowWidth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUserInfos());
    }, 2000);
  }, []); //eslint-disable-line

  return (
    <div
      className="flex h-full min-h-dvh flex-col bg-darkGrey-100 p-4 md:p-8"
      style={{ flexFlow: "column" }}
    >
      <div className="mb-6">
        <BackButton onClick={() => navigate("/")} displayText={isDesktop} />
      </div>

      <div className="flex flex-auto flex-col items-center md:flex-row md:items-stretch md:space-x-12">
        {/* Left part */}
        <div className="flex w-full max-w-[316px] flex-col items-center justify-between rounded-[20px] p-4 py-6 md:bg-white md:p-6">
          <div className="flex flex-col items-center">
            <div className="flex h-[108px] w-[108px] items-center justify-center rounded-full bg-darkGrey-800 text-4xl font-medium text-white">
              {user?.firstName?.charAt(0)}
            </div>
            <span className="mt-4 text-xl font-bold text-secondaryGrey-900">
              {user?.firstName} {user?.lastName}
            </span>
            <span className="text-darkgrey-700 mt-1 text-base font-medium">
              {jobOptions.find((job) => job.value === user?.jobRole)?.name}
            </span>
          </div>

          {isDesktop && <AccountStats />}
        </div>
        {/* Right part */}
        <div className="w-full">
          <h4 className="mb-4 font-bold">
            {translate("ns1:Account.Subscription")}
          </h4>
          <div className="w-full rounded-[20px] bg-white">
            <SubscriptionInfos />
            <HorizontalDivider />
            <SubcriptionManagement />
          </div>

          <h4 className="mb-4 mt-12 font-bold">
            {translate("ns1:Account.AboutYou")}
          </h4>
          <AboutYou />
        </div>
      </div>
    </div>
  );
}
