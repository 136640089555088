import { HttpService } from "data/http-service";
import { FavoriteActivity } from "domain/entities/activities.entities";
import { Snackbar } from "ui/pages/snackbar";
import { translate } from "ui/i18n";
import { AxiosError } from "axios";
import { ActivitiesGateway } from "domain/activities/activities.gateway";
import {
  favoriteActivityDtoSchema,
  favoriteActivitiesDtoSchema,
} from "data/activities/activities.dto.schemas";

export class ActivitiesHttpGateway implements ActivitiesGateway {
  async setTechniqueFavorite(techniqueId: number): Promise<{
    technique: FavoriteActivity | null;
    success: boolean;
  }> {
    const url = `user-favorites`;
    return HttpService.post(url, { techniqueId })
      .then((response) => {
        if (response.status === 201) {
          const parsedActivity = favoriteActivityDtoSchema.safeParse(
            response.data
          );
          if (parsedActivity.success) {
            return {
              technique: parsedActivity.data,
              success: true,
            };
          } else return { technique: null, success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Activities.SetFavoriteError"),
          });
          return {
            technique: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Activities.SetFavoriteError"),
        });
        return { technique: null, success: false };
      });
  }

  async setSeanceFavorite(seanceId: number): Promise<{
    seance: FavoriteActivity | null;
    success: boolean;
  }> {
    const url = `user-favorites`;
    return HttpService.post(url, { seanceId })
      .then((response) => {
        if (response.status === 201) {
          const parsedActivity = favoriteActivityDtoSchema.safeParse(
            response.data
          );
          if (parsedActivity.success) {
            return {
              seance: parsedActivity.data,
              success: true,
            };
          } else return { seance: null, success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Activities.SetFavoriteError"),
          });
          return {
            seance: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Activities.SetFavoriteError"),
        });
        return { seance: null, success: false };
      });
  }

  async getFavoriteActivities(): Promise<{
    activities: FavoriteActivity[];
    success: boolean;
  }> {
    const url = `user-favorites`;
    return HttpService.get(url)
      .then((response) => {
        if (response.status === 200) {
          const parsedActivities = favoriteActivitiesDtoSchema.safeParse(
            response.data
          );
          if (parsedActivities.success) {
            return {
              activities: parsedActivities.data,
              success: true,
            };
          } else return { activities: [], success: false };
        } else {
          Snackbar.showError({
            title: translate("ns1:Activities.GetFavoriteError"),
          });
          return {
            activities: [],
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Activities.GetFavoriteError"),
        });
        return { activities: [], success: false };
      });
  }

  async removeFavoriteActivity(id: number): Promise<{
    id: number | null;
    success: boolean;
  }> {
    const url = `user-favorites/${id}`;
    return HttpService.delete(url)
      .then((response) => {
        if (response.status === 200) {
          return {
            id,
            success: true,
          };
        } else {
          Snackbar.showError({
            title: translate("ns1:Activities.RemoveFavoriteError"),
          });
          return {
            id: null,
            success: false,
          };
        }
      })
      .catch((error: AxiosError) => {
        Snackbar.showError({
          title: translate("ns1:Activities.RemoveFavoriteError"),
        });
        return { id: null, success: false };
      });
  }
}
