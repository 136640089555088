import { selectIsAuthenticationLoading } from "domain/auth/auth.selectors";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { Inputs } from "ui/pages/inputsType";
import { INInput } from "ui/pages/app/components/INInput";
import { sendResetPassword } from "domain/auth/auth.actions";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { Snackbar } from "ui/pages/snackbar";

export function ForgotPassword() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const email = location.state?.email || "";
  const isLoading = useAppSelector(selectIsAuthenticationLoading);

  const { handleSubmit, formState, register } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      email,
    },
  });
  const { errors, isValid } = formState;

  const handleFormSubmit = async (data: Inputs) => {
    const result = await dispatch(sendResetPassword(data.email)).unwrap();

    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:ForgotPassword.EmailSent"),
      });
      navigate("/login", { state: { email: data.email } });
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">
        {translate("ns1:ForgotPassword.ResetPassword")}
      </h2>

      <div className="mt-14 w-full max-w-[430px]">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <INInput
            error={errors.email}
            label={translate("ns1:Input.Labels.Email")}
            className="mb-4 md:mb-8"
            register={{
              ...register("email", {
                required: translate("ns1:Input.Errors.Required"),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: translate("ns1:Input.Errors.EmailFormat"),
                },
              }),
            }}
          />

          <PrimaryButton
            disabled={!isValid || isLoading}
            width="100%"
            height="52px"
            type="submit"
          >
            {translate("ns1:Button.Validate")}
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
}
