import { createSlice } from "@reduxjs/toolkit";
import {
  checkUserIsLogged,
  getPortalSession,
  getUserInfos,
  loginUser,
  registerUser,
  resendVerifyUser,
  updatePassword,
  sendResetPassword,
  updateUser,
  verifyEmail,
  verifyUser,
  updatePersonalInfos,
} from "domain/auth/auth.actions";

export type AuthState = {
  isAuthenticated: boolean;
  isAuthenticationLoading: boolean;
  userInfos: {
    email?: string;
    firstName?: string;
    id?: number;
    jobRole?: string;
    lastName?: string;
    newsletter?: boolean;
    isPremium?: boolean;
    isCustomer?: boolean;
  };
  portalSessionUrl: string | null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticationLoading: false,
  userInfos: {},
  portalSessionUrl: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //REGISTER USER
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(registerUser.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //UPDATE USER
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(updateUser.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //VERIFY USER
    builder.addCase(verifyUser.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(verifyUser.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //VERIFY EMAIL
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(verifyEmail.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //LOGIN USER
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
      if (action.payload.success === true && action.payload.userInfos) {
        state.userInfos = action.payload.userInfos;
      }
    });
    builder.addCase(loginUser.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //RESEND VERIFY USER
    builder.addCase(resendVerifyUser.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(resendVerifyUser.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //CHECK USER IS LOGGED
    builder.addCase(checkUserIsLogged.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
      state.isAuthenticated = action.payload.isLogged;
    });
    builder.addCase(checkUserIsLogged.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //GET USER INFOS
    builder.addCase(getUserInfos.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
      if (action.payload.user) {
        state.userInfos = {
          ...action.payload.user,
          newsletter: !!action.payload.user.newsletter,
        };
      }
    });
    builder.addCase(getUserInfos.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //GET PORTAL SESSION
    builder.addCase(getPortalSession.fulfilled, (state, action) => {
      state.portalSessionUrl = action.payload.session?.url || null;
      state.isAuthenticationLoading = false;
    });
    builder.addCase(getPortalSession.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //SEND RESET PASSWORD
    builder.addCase(sendResetPassword.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(sendResetPassword.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //UPDATE PASSWORD
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
    //UPDATE PERSONAL INFOS
    builder.addCase(updatePersonalInfos.fulfilled, (state, action) => {
      state.isAuthenticationLoading = false;
    });
    builder.addCase(updatePersonalInfos.pending, (state, action) => {
      state.isAuthenticationLoading = true;
    });
  },
});
