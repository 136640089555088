import { Technique } from "domain/entities/techniques.entities";
import { MouseEvent } from "react";
import { HiPlus } from "react-icons/hi";
import { getDurationInHours } from "ui/utils/formatters";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  technique: Technique;
  onClick: () => void;
}

export const PauseCard = ({ technique, onClick }: IProps) => {
  const isDesktop = useWindowWidth();

  const handleSelectionClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <div
      className={`flex h-full flex-col justify-between rounded-[20px] bg-white p-3 md:p-5`}
    >
      <span className="mt-2 block text-base font-bold">{technique.title}</span>

      <div className="mt-4 flex items-end justify-between">
        <div className="rounded-[36px] bg-darkGrey-200 px-3 py-1 text-xs font-semibold">
          {getDurationInHours(Number(technique.duration))}
        </div>

        <button
          onClick={(e) => handleSelectionClick(e)}
          className="flex h-8 w-8 items-center justify-center rounded-xl bg-darkGrey-800 md:h-11 md:w-11"
        >
          <HiPlus size={isDesktop ? 24 : 20} color="#fff" />
        </button>
      </div>
    </div>
  );
};
