import { Opinion as OpinionType } from "domain/entities/techniques.entities";
import { HiChat, HiThumbUp } from "react-icons/hi";
import { translate } from "ui/i18n";
import { DateTime } from "luxon";

interface IProps {
  testimonials: OpinionType[];
}

export function Opinions({ testimonials }: IProps) {
  return (
    <>
      {testimonials?.length > 0 &&
        testimonials.map((testimonial, index) => (
          <div className="mt-10" key={"testimonial" + index}>
            <div className="flex flex-row space-x-4">
              <span
                className={`flex h-12 w-12 min-w-12 items-center justify-center rounded-2xl ${
                  testimonial.type === "APPROVEDBY"
                    ? "bg-paleGreen-100"
                    : "bg-pink-100"
                }`}
              >
                {testimonial.type === "APPROVEDBY" ? (
                  <HiThumbUp color="#409E91" size={24} />
                ) : (
                  <HiChat color="#E14277" size={24} />
                )}
              </span>
              <div className="flex w-full flex-row items-center">
                <div className="flex w-full flex-col space-y-1">
                  <p className="text-base font-bold text-secondaryGrey-900">
                    {testimonial.type === "APPROVEDBY"
                      ? translate("ns1:Activities.ApprovedBy")
                      : translate("ns1:Activities.WhatDoTheyThink")}
                  </p>
                  <p className="text-xs font-medium text-darkGrey-700">
                    {testimonial.author}
                  </p>
                </div>
                <p className="text-xs font-medium text-darkGrey-700">
                  {DateTime.fromMillis(
                    new Date(testimonial.date).getTime()
                  ).toFormat("dd/MM/yyyy")}
                </p>
              </div>
            </div>
            <p className="mt-8 text-sm font-medium text-darkGrey-700">
              {testimonial.content}
            </p>
          </div>
        ))}
    </>
  );
}
