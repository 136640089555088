import {
  selectIsSeanceLoading,
  selectSeance,
  selectSeanceAdvice,
  selectSeanceMateriels,
} from "domain/seances/seances.selectors";
import {
  HiArrowLeft,
  HiHeart,
  HiOutlineDuplicate,
  HiOutlineHeart,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import {
  getDurationInHours,
  getTotalSeanceDuration,
} from "ui/utils/formatters";
import { SeanceBanner } from "ui/pages/app/seances/seance/SeanceBanner";
import { TechniqueComponent } from "ui/pages/app/seances/seance/TechniqueComponent";
import { useWindowWidth } from "ui/utils/hooks";
import { Advice } from "ui/pages/app/components/activities/Advice";
import { Note } from "ui/pages/app/components/activities/Note";
import { Materiels } from "ui/pages/app/components/activities/Materiels";
import { selectUserName } from "domain/auth/auth.selectors";
import { selectFavoriteSeances } from "domain/activities/activities.selectors";
import { toggleSeanceFavorite } from "ui/utils/toggle-activity-favorite";
import { Trans } from "react-i18next";
import { deleteSeance, duplicateSeance } from "domain/seances/seances.actions";
import { Snackbar } from "ui/pages/snackbar";
import { ModalDeleteRessource } from "ui/pages/app/components/ModalDeleteRessource";
import { useDisclosure } from "@chakra-ui/hooks";

export function Seance() {
  const seance = useAppSelector(selectSeance);
  const advice = useAppSelector(selectSeanceAdvice);
  const materiels = useAppSelector(selectSeanceMateriels);
  const isLoading = useAppSelector(selectIsSeanceLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDesktop = useWindowWidth();
  const modalDeleteState = useDisclosure();
  const userName = useAppSelector(selectUserName);
  const favoriteSeance = useAppSelector(selectFavoriteSeances).find(
    (t) => t.seanceId === seance?.id
  );

  const handleFavoriteClick = async (e: any) => {
    e.stopPropagation();
    toggleSeanceFavorite({
      favoriteActivity: favoriteSeance,
      dispatch,
      activityId: seance.id,
    });
  };

  const handleDuplication = async () => {
    const result = await dispatch(duplicateSeance(seance.id)).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Seance.Duplicated"),
      });
    }
  };

  const handleDeleteSeance = async () => {
    const result = await dispatch(deleteSeance(seance.id)).unwrap();
    if (result.success) {
      Snackbar.showSuccess({
        title: translate("ns1:Activities.RessourceDeleted"),
      });
      navigate("/my-creations");
    }
  };

  const handleEditSeance = async () => {
    navigate("/create-seance", { state: { seanceId: seance.id } });
  };

  return (
    <div className="flex h-full flex-col p-4 md:flex-row md:py-8 md:pl-6 md:pr-10">
      <div className="my-4 flex w-full items-center justify-between md:my-0 md:w-12 md:items-start">
        <button
          onClick={() => navigate(-1)}
          className="z-50 flex h-5 items-center"
        >
          <HiArrowLeft size={24} className="text-darkGrey-800" />
        </button>
        {!isDesktop && (
          <div className="flex w-fit space-x-4">
            <button
              className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
              onClick={handleFavoriteClick}
            >
              {!!favoriteSeance?.id ? (
                <HiHeart className="text-secondaryGrey-900" size={20} />
              ) : (
                <HiOutlineHeart className="text-secondaryGrey-900" size={20} />
              )}
            </button>
            {/* <button className="flex h-10 w-10 items-center justify-center rounded-xl bg-white">
              <HiDownload className="text-secondaryGrey-900" size={20} />
            </button> */}
            {seance?.userId && (
              <button
                className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
                onClick={handleEditSeance}
              >
                <HiOutlinePencil className="text-secondaryGrey-900" size={20} />
              </button>
            )}
            {seance?.userId && (
              <button
                className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
                onClick={handleDuplication}
              >
                <HiOutlineDuplicate
                  className="text-secondaryGrey-900"
                  size={20}
                />
              </button>
            )}
            {seance?.userId && (
              <button
                className="flex h-10 w-10 items-center justify-center rounded-xl bg-white"
                onClick={() => modalDeleteState.onOpen()}
              >
                <HiOutlineTrash className="text-secondaryGrey-900" size={20} />
              </button>
            )}
          </div>
        )}
      </div>

      {seance && !isLoading ? (
        <>
          {" "}
          <div className="flex w-full flex-col space-y-6 md:space-y-8">
            <SeanceBanner
              seance={seance}
              isFavorite={!!favoriteSeance?.id}
              handleFavoriteClick={handleFavoriteClick}
              handleDuplication={handleDuplication}
              openDeleteModal={() => modalDeleteState.onOpen()}
              handleEditSeance={handleEditSeance}
            />

            <div className="flex flex-wrap gap-x-3 gap-y-3 md:justify-start">
              {seance?.userId && (
                <div className="flex flex h-7 w-7 items-center items-center justify-center rounded-full bg-darkGrey-700 text-xs font-semibold text-white">
                  {userName?.charAt(0)}
                </div>
              )}
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-700 px-3 text-xs font-semibold text-white">
                {getDurationInHours(getTotalSeanceDuration(seance))}
              </div>
              <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                {seance?.onsite &&
                  seance?.remote &&
                  translate("ns1:Activities.remote&onsite")}
                {seance?.onsite &&
                  !seance?.remote &&
                  translate("ns1:Activities.onsite")}
                {!seance?.onsite &&
                  seance?.remote &&
                  translate("ns1:Activities.remote")}
              </div>
              {seance.participants ? (
                <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                  <Trans
                    i18nKey={
                      "Activities.UntilParticipants" as
                        | never[]
                        | (string & never[])
                    }
                    values={{ participants: seance.participants }}
                  />
                </div>
              ) : (
                <></>
              )}
              {seance.participants === 0 && (
                <div className="flex h-7 items-center rounded-[36px] bg-darkGrey-200 px-3 text-xs font-semibold text-darkGrey-700">
                  <Trans
                    i18nKey={
                      "Activities.MoreThanParticipants" as
                        | never[]
                        | (string & never[])
                    }
                    values={{ participants: 20 }}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col space-x-0 space-y-6 md:flex-row md:space-x-8 md:space-y-0">
              <div className="flex w-full flex-col space-y-6 md:space-y-8">
                <div className="rounded-[30px] bg-white p-4 py-6 md:p-6">
                  <span className="text-base font-medium md:text-sm">
                    {`${translate("ns1:Activities.Goal")}: ${seance?.objectif}`}
                  </span>
                  <span className="mt-4 block text-sm font-medium">
                    {translate("ns1:Activities.ActivitySteps")}
                  </span>
                  {seance?.techniques.length > 0 &&
                    seance?.techniques.map((technique, index) => (
                      <TechniqueComponent
                        key={"technique" + index}
                        technique={technique}
                        index={index}
                        techniquesLength={seance?.techniques.length}
                      />
                    ))}
                </div>

                {/* advice and equipment for mobile */}
                {!isDesktop &&
                  (advice || seance?.note || materiels.length > 0) && (
                    <div className="flex flex-col space-y-6 md:space-y-8">
                      {seance?.note && <Note note={seance?.note} />}
                      {materiels?.length > 0 && (
                        <Materiels materiels={materiels} />
                      )}
                      {advice && <Advice advice={advice} />}
                    </div>
                  )}
              </div>

              {/* advice and equipment for desktop */}
              {isDesktop &&
                (advice || seance?.note || materiels.length > 0) && (
                  <div className="flex w-80 flex-col space-y-6 md:space-y-8">
                    {seance?.note && <Note note={seance?.note} />}
                    {materiels?.length > 0 && (
                      <Materiels materiels={materiels} />
                    )}
                    {advice && <Advice advice={advice} />}
                  </div>
                )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <div className="loader" />
        </div>
      )}

      <ModalDeleteRessource
        isOpen={modalDeleteState.isOpen}
        onClose={modalDeleteState.onClose}
        deleteRessource={handleDeleteSeance}
      />
    </div>
  );
}
