import { Carousel } from "react-responsive-carousel";
import BannerLightGreenDesktop from "ui/assets/svg/carrousel-banners-desktop/banner-light-green-desktop.svg";
import BannerPaleGreenDesktop from "ui/assets/svg/carrousel-banners-desktop/banner-pale-green-desktop.svg";
import BannerPinkDesktop from "ui/assets/svg/carrousel-banners-desktop/banner-pink-desktop.svg";
import BannerLightGreenMobile from "ui/assets/svg/carrousel-banners-mobile/banner-light-green-mobile.svg";
import BannerPaleGreenMobile from "ui/assets/svg/carrousel-banners-mobile/banner-pale-green-mobile.svg";
import BannerPinkMobile from "ui/assets/svg/carrousel-banners-mobile/banner-pink-mobile.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { translate } from "ui/i18n";
import { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useWindowWidth } from "ui/utils/hooks";
import { useAppSelector } from "ui/hook/store";
import { selectCarouselSlides } from "domain/carousel/carousel.selectors";
import { CarouselSlideType } from "domain/entities/carousel.entities";

export function HomeCarousel() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isDesktop = useWindowWidth();
  const slides = useAppSelector(selectCarouselSlides);

  const previousImage = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? slides.length - 1 : currentImageIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex(
      currentImageIndex === slides.length - 1 ? 0 : currentImageIndex + 1
    );
  };

  return (
    <div className="relative">
      <Carousel
        className="mt-6 rounded-[20px] md:rounded-3xl"
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        autoPlay
        infiniteLoop
        selectedItem={currentImageIndex}
        onChange={setCurrentImageIndex}
        transitionTime={600}
        interval={5000}
      >
        {slides?.map((slide, index) => (
          <div key={index + "slide"}>
            <img
              src={getCarouselImg(isDesktop, slide.type)}
              alt={translate("ns1:Home.BannerAlt")}
              className="rounded-[20px] md:rounded-3xl"
            />
            <div className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center p-4 md:w-5/6 md:p-0">
              <div className="mb-2 w-fit rounded-[36px] bg-white/65 px-2.5 py-0.5 text-xs font-semibold md:mb-4">
                {translate(`ns1:Carousel.Types.${slide.type}`)}
              </div>
              <h1 className="mb-3 text-lg md:mb-4 md:text-3xl">
                {slide.title}
              </h1>
              <button
                className="mb-2 rounded-xl bg-darkGrey-800 px-4 py-2 text-sm font-bold text-white md:mb-0"
                onClick={() => window.open(slide.link, "_blank").focus()}
              >
                {translate("ns1:Carousel.SeeMore")}
              </button>
            </div>
          </div>
        ))}
      </Carousel>

      {isDesktop && (
        <>
          <button
            className="absolute left-5 top-1/2 flex h-9 w-9 -translate-y-1/2 transform items-center justify-center rounded-xl bg-white"
            onClick={previousImage}
          >
            <HiChevronLeft className="text-darkGrey-900" size={18} />
          </button>
          <button
            className="absolute right-5 top-1/2 flex h-9 w-9 -translate-y-1/2 transform items-center justify-center rounded-xl bg-white"
            onClick={nextImage}
          >
            <HiChevronRight className="text-darkGrey-900" size={18} />
          </button>
        </>
      )}

      <div className=" absolute bottom-4 left-1/2 flex -translate-x-1/2 transform  space-x-3 md:bottom-5">
        {slides?.map((_, index) => (
          <button
            key={index + "indicator"}
            onClick={() => setCurrentImageIndex(index)}
            className={`h-2.5 w-2.5 rounded-full ${
              index === currentImageIndex ? "bg-white" : "bg-darkGrey-800"
            }`}
          />
        ))}
      </div>
    </div>
  );
}

const getCarouselImg = (isDesktop: boolean, type: CarouselSlideType) => {
  if (type === "NEWS") {
    return isDesktop ? BannerLightGreenDesktop : BannerLightGreenMobile;
  } else if (type === "TOOLS") {
    return isDesktop ? BannerPinkDesktop : BannerPinkMobile;
  } else if (type === "EVENTS") {
    return isDesktop ? BannerPaleGreenDesktop : BannerPaleGreenMobile;
  } else return isDesktop ? BannerLightGreenDesktop : BannerLightGreenMobile;
};
