import { selectUserInfos } from "domain/auth/auth.selectors";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";

export function SubscriptionInfos() {
  const user = useAppSelector(selectUserInfos);
  return (
    <div className="p-4 py-6 md:p-6">
      <div
        className={`mb-6 w-fit rounded-[36px] px-4 py-1 text-base font-semibold ${
          user?.isPremium
            ? "bg-pink-400 text-white"
            : "bg-darkGrey-200 text-secondaryGrey-800"
        }`}
      >
        {translate(`ns1:Account.${user?.isPremium ? "Premium" : "Free"}`)}
      </div>
      {user?.isPremium ? (
        <>
          <div className="flex space-x-3">
            <HiOutlineCheckCircle size={20} color="#E14277" />
            <span className="text-sm font-medium text-darkGrey-700">
              {translate("ns1:Account.AccessToAllTechniquesAndSeances")}
            </span>
          </div>
          <div className="mt-4 flex space-x-3">
            <HiOutlineCheckCircle size={20} color="#E14277" />
            <span className="text-sm font-medium text-darkGrey-700">
              {translate("ns1:Account.SeanceCreation")}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="flex space-x-3">
            <HiOutlineCheckCircle size={20} color="#47548C" />
            <span className="text-sm font-medium text-darkGrey-700">
              {translate("ns1:Account.AccessToFreeTechniques")}
            </span>
          </div>
          <div className="mt-4 flex space-x-3">
            <HiOutlineCheckCircle size={20} color="#47548C" />
            <span className="text-sm font-medium text-darkGrey-700">
              {translate("ns1:Account.AccessToCommunitySeances")}
            </span>
          </div>
        </>
      )}
    </div>
  );
}
