import { createSlice } from "@reduxjs/toolkit";
import {} from "domain/auth/auth.actions";
import {
  getFavoriteActivities,
  removeFavoriteActivity,
  setSeanceFavorite,
  setTechniqueFavorite,
} from "domain/activities/activities.actions";
import { FavoriteActivity } from "domain/entities/activities.entities";

export type ActivitiesState = {
  favoriteSeances: FavoriteActivity[];
  favoriteTechniques: FavoriteActivity[];
  isActivitiesLoading: boolean;
};

const initialState: ActivitiesState = {
  favoriteSeances: [],
  favoriteTechniques: [],
  isActivitiesLoading: false,
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {},
  extraReducers(builder) {
    //SET TECHNIQUE FAVORITE
    builder.addCase(setTechniqueFavorite.fulfilled, (state, action) => {
      if (action.payload.success && action.payload.technique) {
        state.favoriteTechniques.push(action.payload.technique);
      }
      state.isActivitiesLoading = false;
    });
    builder.addCase(setTechniqueFavorite.pending, (state, action) => {
      state.isActivitiesLoading = true;
    });
    //SET SEANCE FAVORITE
    builder.addCase(setSeanceFavorite.fulfilled, (state, action) => {
      if (action.payload.success && action.payload.seance) {
        state.favoriteSeances.push(action.payload.seance);
      }
      state.isActivitiesLoading = false;
    });
    builder.addCase(setSeanceFavorite.pending, (state, action) => {
      state.isActivitiesLoading = true;
    });
    //GET FAVORITE ACTIVITIES
    builder.addCase(getFavoriteActivities.fulfilled, (state, action) => {
      if (action.payload.success && action.payload.activities) {
        state.favoriteTechniques = [];
        state.favoriteSeances = [];
        action.payload.activities.forEach((t) => {
          if (t.type === "technique") {
            state.favoriteTechniques.push(t);
          } else if (t.type === "seance") {
            state.favoriteSeances.push(t);
          }
        });
      }
      state.isActivitiesLoading = false;
    });
    builder.addCase(getFavoriteActivities.pending, (state, action) => {
      state.isActivitiesLoading = true;
    });
    //REMOVE FAVORITE ACTIVITY
    builder.addCase(removeFavoriteActivity.fulfilled, (state, action) => {
      state.isActivitiesLoading = false;
    });
    builder.addCase(removeFavoriteActivity.pending, (state, action) => {
      state.isActivitiesLoading = true;
    });
  },
});
