import { selectIsActivitiesLoading } from "domain/activities/activities.selectors";
import { Technique } from "domain/entities/techniques.entities";
import {
  selectIsTechniqueLoading,
  selectTechniques,
} from "domain/techniques/techniques.selectors";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "ui/hook/store";
import { translate } from "ui/i18n";
import { PageHeader } from "ui/pages/app/components/PageHeader";
import { TechniqueCard } from "ui/pages/app/components/activities/TechniqueCard";
import { INSkeleton } from "ui/pages/app/components/INSkeleton";
import {
  selectIsSeanceLoading,
  selectSeances,
} from "domain/seances/seances.selectors";
import { SeanceCard } from "ui/pages/app/components/activities/SeanceCard";
import { Seance } from "domain/entities/seances.entities";
import { selectIsPremium } from "domain/auth/auth.selectors";
import { Snackbar } from "ui/pages/snackbar";

export function Favorites() {
  const isLoading = useAppSelector(selectIsActivitiesLoading);
  const isTechniqueLoading = useAppSelector(selectIsTechniqueLoading);
  const isSeanceLoading = useAppSelector(selectIsSeanceLoading);
  const techniques = useAppSelector(selectTechniques);
  const seances = useAppSelector(selectSeances);
  const isPremium = useAppSelector(selectIsPremium);
  const navigate = useNavigate();

  const handleTechniqueCardClick = async (technique: Technique) => {
    if (!isPremium && !!technique.premium) {
      await Snackbar.removeSubscriptionAlert();
      Snackbar.showSubscriptionAlert(() => navigate("/account"), "technique");
    } else navigate(`/technique/${technique?.id}`);
  };

  const handleSeanceCardClick = async (seance: Seance) => {
    if (!isPremium && !!seance.premium) {
      await Snackbar.removeSubscriptionAlert();
      Snackbar.showSubscriptionAlert(() => navigate("/account"), "seance");
    } else navigate(`/seance/${seance?.id}`);
  };

  return (
    <div className={`flex h-full w-full flex-row overflow-x-hidden`}>
      <div className="h-full w-full px-2 pt-2 md:px-12 md:pt-8">
        <PageHeader title={translate("ns1:Routes.Favorites")} />

        {/* Favorite Techniques */}
        <h2 className="mt-10 ps-5 text-secondaryGrey-900">
          {translate("ns1:Favorites.FavoriteTechniques")}
        </h2>
        <div className="mb-8 mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {!isLoading && !isTechniqueLoading && techniques ? (
            <>
              {techniques.map((technique, index) => (
                <TechniqueCard
                  technique={technique}
                  key={"technique" + index}
                  onClick={() => handleTechniqueCardClick(technique)}
                />
              ))}
            </>
          ) : (
            <>
              {[...Array(4)].map((_, index) => (
                <INSkeleton key={"techniques-skeleton" + index} />
              ))}
            </>
          )}
        </div>

        {/* Favorite Seances */}
        <h2 className="mt-10 ps-5 text-secondaryGrey-900">
          {translate("ns1:Favorites.FavoriteSeances")}
        </h2>
        <div className="mb-8 mt-4 grid w-full grid-cols-2 gap-2 md:mt-8 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
          {!isLoading && !isSeanceLoading && seances ? (
            <>
              {seances.map((seance, index) => (
                <SeanceCard
                  seance={seance}
                  key={"seance" + index}
                  onClick={() => handleSeanceCardClick(seance)}
                />
              ))}
            </>
          ) : (
            <>
              {[...Array(4)].map((_, index) => (
                <INSkeleton key={"seances-skeleton" + index} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
