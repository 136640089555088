import { translate } from "ui/i18n";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { useWindowWidth } from "ui/utils/hooks";

interface IProps {
  onSave: () => void;
  onSaveContinue: () => void;
  isLoading: boolean;
  canSave: boolean;
}

export function FinishFooter({
  onSave,
  onSaveContinue,
  isLoading,
  canSave,
}: IProps) {
  const isDesktop = useWindowWidth();

  return (
    <div className="sticky bottom-0 flex w-full flex-col items-center bg-white px-6 py-4 shadow-lg">
      <div className="w-full max-w-[590px]">
        <PrimaryButton
          type="submit"
          height="46px"
          width="100%"
          disabled={isLoading || !canSave}
          onClick={onSave}
        >
          {translate("ns1:Button.Finish")}
        </PrimaryButton>

        {!isDesktop && (
          <PrimaryButton
            height="46px"
            width="100%"
            disabled={isLoading}
            outlined
            onClick={onSaveContinue}
            className="mt-4"
          >
            {translate("ns1:Button.SaveContinueLater")}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}
