import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import { BackButton } from "ui/pages/app/components/BackButton";
import { translate } from "ui/i18n";
import { PrimaryButton } from "ui/pages/app/components/PrimaryButton";
import { useWindowWidth } from "ui/utils/hooks";
import { HiFolderOpen, HiTrash } from "react-icons/hi";
import { DeleteRessource } from "ui/assets/svg/DeleteRessource";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  deleteRessource: (e: any) => any;
}

export function ModalDeleteRessource({
  isOpen,
  onClose,
  deleteRessource,
}: IProps) {
  const isDesktop = useWindowWidth();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="750px" margin="20px" className="!rounded-[30px] p-8">
        <BackButton onClick={onClose} />
        <div className="mt-12 flex flex-col items-center">
          <h4 className="w-fit text-center font-bold text-secondaryGrey-900">
            {translate("ns1:Activities.AreYouSureDelete")}
          </h4>

          <div className="mt-16" style={{ maxWidth: 500, width: "100%" }}>
            <DeleteRessource />
          </div>
        </div>

        <div className="mt-16">
          <PrimaryButton
            height={isDesktop ? "52px" : "46px"}
            width="100%"
            className="mt-4 md:mt-6"
            onClick={deleteRessource}
          >
            <HiTrash color="#fff" size={24} className="me-2" />
            {translate("ns1:Activities.DeleteRessource")}
          </PrimaryButton>

          <PrimaryButton
            height={isDesktop ? "52px" : "46px"}
            width="100%"
            className="mt-4 md:mt-6"
            outlined
            onClick={onClose}
          >
            <HiFolderOpen color="#2D396B" size={24} className="me-2" />
            {translate("ns1:Activities.KeepRessource")}
          </PrimaryButton>
        </div>
      </ModalContent>
    </Modal>
  );
}
