import { useEffect, useState } from "react";
import { SeanceInfos } from "ui/pages/app/seances/create-seance/steps/SeanceInfos";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Inputs } from "ui/pages/inputsType";
import { useAppDispatch } from "ui/hook/store";
import { getSeance, getSeanceTechniques } from "domain/seances/seances.actions";
import { formatDuration } from "ui/utils/formatters";
import { Seance } from "domain/entities/seances.entities";
import { AddContent } from "ui/pages/app/seances/create-seance/steps/add-content/AddContent";

export function CreateSeance() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isSeanceLoading, setIsSeanceLoading] = useState(false);
  const [seanceId, setSeanceId] = useState<number | null>(
    location.state?.seanceId || null
  );
  const [seance, setSeance] = useState<Seance | null>(null);

  const formManager = useForm<Inputs>({
    mode: "onBlur",
    defaultValues: {
      objectif: "",
      duration: "",
      title: "",
      modality: "",
      participants: "",
    },
  });

  const { setValue } = formManager;

  const fetchSeance = (id?: number) => {
    return new Promise<void>(async (resolve) => {
      const { seance } = await dispatch(getSeance(id || seanceId)).unwrap();

      if (seance) {
        setValue("title", seance.title);
        setValue("objectif", seance.objectif);
        setValue("duration", formatDuration(seance.duration));
        setValue("modality", seance.onsite ? "onsite" : "remote");
        setValue("participants", seance.participants?.toString());
      } else navigate("/my-creations");

      setSeance(seance);

      const result = await dispatch(getSeanceTechniques(seance.id)).unwrap();

      setTimeout(() => {
        if (
          (result &&
            result.techniques.length > 0 &&
            !seance.title.includes("- Copie")) ||
          (result && seance.active)
        ) {
          setStep(2);
        }
        resolve();
      });
    });
  };

  const fetchSeanceInfos = async () => {
    setIsSeanceLoading(true);
    await fetchSeance();
    setIsSeanceLoading(false);
  };

  useEffect(() => {
    if (seanceId) {
      fetchSeanceInfos();
    }
  }, [seanceId]); //eslint-disable-line

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    if (step !== 1) setStep(step - 1);
  };

  const handleBackButton = () => {
    if (step === 1) {
      navigate("/my-creations");
    } else previousStep();
  };

  return (
    <>
      {!isSeanceLoading ? (
        <>
          {step === 1 && (
            <SeanceInfos
              handleBackButton={handleBackButton}
              nextStep={nextStep}
              formManager={formManager}
              setSeanceId={setSeanceId}
              seanceId={seanceId}
              fetchSeance={fetchSeance}
            />
          )}
          {step === 2 && (
            <AddContent
              handleBackButton={handleBackButton}
              seanceId={seanceId}
              seance={seance}
              fetchSeance={fetchSeance}
            />
          )}
        </>
      ) : (
        <div className="flex h-dvh w-full items-center justify-center">
          <div className="loader" />
        </div>
      )}
    </>
  );
}
